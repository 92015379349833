<template>
  <div>
    <div id="share-account-add" class="share-account-box add" @click="$emit('click')">
      <b-button variant="link"> <svg-icon name="plus" /> </b-button>
    </div>
    <b-tooltip v-if="tip" target="share-account-add" variant="info" triggers="hover" placement="top" boundary="window">
      {{ tip }}
    </b-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: String,
      default: '',
    },
  },
};
</script>
