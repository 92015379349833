<template>
  <div ref="quotesScroll" class="panel-scroll design-fab-quote-panel">
    <div class="panel-body">
      <h6 class="mb-3">Find Quotes</h6>
      <b-form-input
        @keyup.enter="refetchQuotes"
        @change="handleQuoteKeywordChange"
        class="bg-light shadow-sm border-0"
        size="sm"
        autocomplete="off"
        placeholder="Enter a keyword and hit enter"
      />

      <div class="mt-4">
        <div v-if="hasQuotes" class="">
          <div v-for="quote in quoteSearch.quotes" :key="quote.id" class="quote-item" @click="handleQuoteSelect(quote)">
            <p class="mb-0">{{ quote.body }}</p>
            <div class="my-2 font-weight-bold text-right">- {{ quote.author }}</div>
          </div>
        </div>
        <div v-else-if="!$apollo.queries.quoteSearch.loading && !hasQuotes" class="text-center mt-4">
          Nothing found! <br />
          Try another keyword.
        </div>
        <div v-if="$apollo.queries.quoteSearch.loading" class="text-center">
          <DesignFabsQuotesSkeleton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QUOTE_SEARCH_QUERY } from '~/graphql/queries';
import DesignFabsQuotesSkeleton from './DesignFabsQuotesSkeleton';

export default {
  name: 'design-fabs-quote-panel',

  components: { DesignFabsQuotesSkeleton },

  data() {
    return {
      quoteSearch: {},
      page: 1,
      quoteKeywords: '',

      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  apollo: {
    quoteSearch: {
      query: QUOTE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.quoteKeywords,
          page: this.page,
        };
      },
    },
  },

  computed: {
    hasQuotes() {
      return this.quoteSearch && this.quoteSearch.quotes && this.quoteSearch.quotes.length > 0;
    },
  },

  methods: {
    handleQuoteSelect(quote) {
      this.$emit('select', quote);
    },

    handleQuoteKeywordChange(input) {
      this.quoteKeywords = input;
    },

    refetchQuotes(event) {
      event.preventDefault();

      this.quoteSearch.quotes = [];

      this.$apollo.queries.quoteSearch.refetch({
        keywords: this.quoteKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.design-fab-quote-panel {
  .quote-item {
    box-shadow: 1px 2px 5px $gray-200;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    border: 1px solid transparent;
    background-color: azure;
    color: $primary;

    &:hover {
      border: 1px solid $primary;
      border-radius: 4px;
    }
  }
}
</style>
