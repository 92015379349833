<template>
  <div ref="articleScroll" class="panel-scroll graphics-panel">
    <div class="panel-body">
      <h6 class="mb-3 mt-2">Frames</h6>
      <div class="images-wrap mt-4">
        <div class="images-grid">
          <div v-for="frame in frames" :key="frame.id" class="image-item" @click="handleFrameSelect(frame)">
            <div :style="{ backgroundImage: `url(${frame.thumb})` }" class="image-img"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const apiRoot = process.env.VUE_APP_API_ROOT;

export default {
  name: 'design-fabs-graphics-panel',

  data() {
    return {
      frames: [
        {
          id: 1,
          imageUrl: `${apiRoot}/designs/frames/frame-1.png`,
          thumb: `${apiRoot}/designs/frames/frame-1_thumb.png`,
        },
        {
          id: 2,
          imageUrl: `${apiRoot}/designs/frames/frame-2.png`,
          thumb: `${apiRoot}/designs/frames/frame-2_thumb.png`,
        },
        {
          id: 3,
          imageUrl: `${apiRoot}/designs/frames/frame-3.png`,
          thumb: `${apiRoot}/designs/frames/frame-3_thumb.png`,
        },
        {
          id: 4,
          imageUrl: `${apiRoot}/designs/frames/frame-4.png`,
          thumb: `${apiRoot}/designs/frames/frame-4_thumb.png`,
        },
      ],
    };
  },

  methods: {
    handleFrameSelect(frame) {
      this.$emit('frameSelect', frame);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.graphics-panel {
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .image-item {
      width: 100px;
      height: 100px;
      margin-bottom: 15px;
      background-color: $light;
      padding: 5px;

      .image-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
