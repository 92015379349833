<template>
  <div class="share-account-box" :class="{ active, disabled }" @click="$emit('click', account.id)">
    <img width="40" height="40" class="share-account-box-img rounded-circle shadow-sm" :src="account.imageUrl" alt="" />
    <div class="account-name">{{ account.name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      required: true,
    },

    active: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.share-account-box {
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border: 1px solid $gray-200;
  border-radius: 0.5rem;
  width: 214px;
  height: 75px;
  position: relative;
  user-select: none;
  box-shadow: 0 1px 5px $gray-200;

  &.add {
    width: 75px;
    justify-content: center;
    color: $primary;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    border-color: $primary;
  }

  .share-account-box-img {
    padding: 3px;
    margin-right: 5px;
  }

  .account-name {
    font-weight: bold;
    font-size: 12px;
    max-width: 77%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .account-type {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $gray-500;
    margin-top: 3px;
    font-size: 14px;
  }

  &.active {
    border-color: $primary;
    border-width: 2px;

    &::after {
      content: '';
      border-right: 15px solid $primary;
      border-left: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-top: 15px solid $primary;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      right: 0;
    }
    &::before {
      position: absolute;
      top: -3px;
      right: 2px;
      content: url('~@/assets/images/check.svg');
      width: 10px;
      height: 10px;
      z-index: 10;
    }
  }
}
</style>
