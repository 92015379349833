<template>
  <div>
    <DesignOverviewComponent editRoute="designer.edit" routeParam="id" />
  </div>
</template>

<script>
import DesignOverviewComponent from '~/components/DesignOverviewComponent';

export default {
  components: {
    DesignOverviewComponent,
  },
};
</script>
