<template>
  <section class="my-5 mx-4">
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex">
            <b-button :to="{ name: 'designer.overview' }" variant="link">
              <svg-icon name="chevron-left" class="mr-1" />
            </b-button>
            <div class="ml-2">
              <h4 class="mb-2 font-weight-bold">Manage Designs</h4>
              <p class="mb-3">View and manage all your designs</p>
            </div>
          </div>

          <b-button :to="{ name: editRoute, params: { [routeParam]: 0 } }" size="sm" variant="primary" class="px-4">
            Create New
          </b-button>
        </div>

        <skeleton-screens
          v-if="$apollo.queries.contentSearch && $apollo.queries.contentSearch.loading"
          class="d-flex justify-content-between flex-wrap"
          itemClass="rounded mb-4"
          width="255px"
          height="255px"
          times="12"
        />
        <div class="contents-wrap d-flex flex-wrap">
          <template v-if="$apollo.queries.contentSearch && !$apollo.queries.contentSearch.loading">
            <div
              v-for="design in contentSearch.designs"
              :key="design.id"
              class="design-template-item"
              :style="{ backgroundImage: `url(${design.imageUrl}` }"
            >
              <div class="design-overlay">
                <b-dropdown variant="clear" offset="5" class="options-btn" size="sm" right no-caret>
                  <template v-slot:button-content>
                    <b-icon icon="three-dots" aria-hidden="true"></b-icon>
                  </template>
                  <b-dropdown-item :to="{ name: 'designer.share', params: { id: design.id } }">
                    <svg-icon name="share-alt" aria-hidden="true"></svg-icon>
                    Share
                  </b-dropdown-item>
                  <b-dropdown-item-button @click="downloadDesign(design)">
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    Download
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="duplicateDesign(design)">
                    <b-icon icon="documents" aria-hidden="true"></b-icon>
                    Duplicate
                  </b-dropdown-item-button>
                  <b-dropdown-item-button variant="danger" @click="invokeDeleteDesign(design)">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                    Delete
                  </b-dropdown-item-button>
                </b-dropdown>
                <b-button
                  :to="{ name: editRoute, params: { [routeParam]: design.id } }"
                  size="sm"
                  variant="primary"
                  class="rounded-pill px-4"
                >
                  Edit
                </b-button>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="$apollo.queries.contentSearch && !$apollo.queries.contentSearch.loading && !hasContents"
          class="bg-white rounded-sm shadow-sm text-center p-5"
        >
          <img class="pt-3" width="300" src="@/assets/images/web_devices.svg" alt="" />
          <h5 class="pb-4 pt-3">No saved Designs yet.</h5>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="DeleteDesignModal"
      content-class="rounded-sm text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHideDeleteDesignModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!deletingDesign">
          <h4 class="font-weight-bold mb-3">Delete Design</h4>
          <p class="m-1">This action cannot be undone</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="deleteDesign">Delete</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import { BANNER_DESIGNS_QUERY } from '~/graphql/queries';
import { DELETE_BANNER_DESIGN_MUTATION } from '~/graphql/mutations';

export default {
  props: {
    editRoute: {
      required: true,
    },
    routeParam: {
      required: true,
    },
  },

  data() {
    return {
      contentSearch: {},
      designInvokedForDelete: null,
      deletingDesign: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      selectedContent: state => state.createPost.selectedContent,
    }),
    hasContents() {
      return this.contentSearch && this.contentSearch.designs && this.contentSearch.designs.length > 0;
    },
  },

  apollo: {
    contentSearch: {
      query: BANNER_DESIGNS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          limit: 10,
        };
      },
      update(data) {
        return data.bannerDesigns;
      },
      result({ data, loading }) {
        if (!loading && data.bannerDesigns) {
          this.contentSearch = data.bannerDesigns;
        }
      },
    },
  },

  methods: {
    downloadDesign(design) {
      window.location.href = `${design.imageUrl}?downloadFile=UntitledDesign`;
    },

    duplicateDesign(design) {
      this.$store.dispatch('design/updateTemplate', design);

      this.$router.push({ name: this.editRoute, params: { [this.routeParam]: 0 } });
    },

    invokeDeleteDesign(design) {
      this.designInvokedForDelete = design;

      this.$bvModal.show('DeleteDesignModal');
    },

    deleteDesign() {
      this.deletingDesign = true;
      const id = parseInt(this.designInvokedForDelete.id, 10);

      this.$apollo
        .mutate({
          mutation: DELETE_BANNER_DESIGN_MUTATION,
          variables: {
            workspace: this.workspace.id,
            id,
          },
          update: (store, { data: { deleteBannerDesign } }) => {
            try {
              const data = store.readQuery({
                query: BANNER_DESIGNS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  limit: 10,
                },
              });
              const deleted = deleteBannerDesign;
              const index = data.bannerDesigns.designs.findIndex(d => d.id === deleted.id);

              if (index !== -1) {
                data.bannerDesigns.designs.splice(index, 1);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: BANNER_DESIGNS_QUERY,
                variables: {
                  workspace: this.workspace.id,
                  limit: 10,
                },
                data,
              });
            } catch (e) {
              // Do something with this error
              console.log(e);
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Design has been deleted successfully.',
          });

          this.$bvModal.hide('DeleteDesignModal');
        })
        .catch(() => {
          this.deletingDesign = false;
          this.$bvModal.hide('DeleteDesignModal');

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideDeleteDesignModal() {
      this.deletingDesign = false;
      this.designInvokedForDelete = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.design-templates-container {
  padding: 40px 50px;
  background-color: $light;
  border-radius: 0.7rem;
}

.contents-wrap {
  margin-top: 1.5rem;
  margin-right: -19px;
}

.design-template-item {
  height: 255px;
  width: 255px;
  margin: 0 19px 24px 0;
  box-shadow: 0 7px 16px 0 rgba(22, 45, 61, 0.05);
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
  background-color: $gray-300;

  img {
    width: 100%;
    max-height: 291px;
    max-width: 291px;
  }

  .design-overlay {
    opacity: 0;
    position: absolute;
    background: rgba(22, 45, 61, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);

    .options-btn {
      position: absolute;
      right: 10px;
      top: 10px;

      .dropdown-toggle {
        width: 26px;
        height: 26px;
        padding: 0;
        border-radius: 20px;
        line-height: 1;
        color: $white;
        border: 1px solid $white;

        &:hover {
          color: $primary;
          background-color: $white;
        }
      }

      .dropdown-menu {
        min-width: 8rem;
        border: none;
        border-radius: 8px;
        box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
        font-size: 14px;
        line-height: 18px;
        color: #162d3d;
        background: #fff;
        margin-top: 10px;

        .dropdown-item {
          padding: 0.25rem 1.2rem;
        }

        &::before {
          content: '';
          transform: rotateZ(-135deg);
          right: 15px;
          top: -4px;
          margin-left: 8px;
          margin-right: 8px;
          border-width: 4px;
          border-color: transparent #fff #fff transparent;
          margin: 0;
          width: 8px;
          height: 8px;
          background: 0 0;
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
          border-style: solid;
          position: absolute;
        }
      }
    }
  }

  &:hover {
    transform: translateY(-6px);

    .design-overlay {
      opacity: 1;
    }
  }
}
</style>
