<template>
  <div class="mt-4">
    <TopWizard
      :backButton="true"
      :goBackHandler="goBackHandler"
      :sequence="sequence"
      :currentStep="2"
      :sidebar="false"
    />

    <b-container class="design__share mx-auto">
      <b-row>
        <b-col cols="7">
          <div class="design__share-tabs">
            <div class="tabs-header">
              <h5 class="font-weight-bold">Share Your Design</h5>
              <p>Pick a platform to share your design</p>
            </div>
            <b-row class="post__nav">
              <b-col class="m-0 p-0 border-right border-primary">
                <div class="d-flex">
                  <b-button
                    class="post__nav-btn"
                    :class="[activeStatus === 'pinterest' ? 'post__nav--active' : ' ']"
                    variant="clear"
                    @click="setActiveStatus('pinterest')"
                  >
                    <svg-icon name="pinterest-alt" class="mr-1" size="sm" />
                    Pinterest
                  </b-button>
                  <b-button
                    class="post__nav-btn"
                    :class="[activeStatus === 'facebook' ? 'post__nav--active' : ' ']"
                    variant="clear"
                    @click="setActiveStatus('facebook')"
                  >
                    <svg-icon name="facebook-alt" class="mr-1" size="sm" />
                    Facebook
                  </b-button>
                </div>
              </b-col>
              <b-col class="m-0 p-0">
                <div class="d-flex">
                  <b-dropdown
                    v-b-tooltip
                    title="Insert design in composer"
                    class="custom-dropdown"
                    toggle-class="post__nav-btn align-self-center"
                    variant="clear"
                    size="sm"
                    no-caret
                    no-flip
                    right
                    offset="1"
                  >
                    <template slot="button-content">
                      <svg-icon name="edit-alt" size="sm" />
                      Composer
                      <svg-icon name="chevron-down" size="sm" class="ml-1 mt-0" />
                    </template>
                    <div class="py-2">
                      <b-dropdown-item @click="insertInComposer('social')">
                        <svg-icon name="post-alt" class="mr-1" />
                        Social Post
                      </b-dropdown-item>
                      <b-dropdown-item @click="insertInComposer('blog')">
                        <svg-icon name="globe" class="mr-1" />
                        Blog Post
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <b-button class="post__nav-btn" variant="clear" @click="downloadDesign">
                    <svg-icon name="download-alt" size="sm" class="mr-1" />
                    Download
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <PinterestTab
              v-if="activeStatus === 'pinterest'"
              :loading="accountsLoading"
              :design="bannerDesignById"
              :accounts="pinterestAccounts"
            />
            <FacebookTab
              v-if="activeStatus === 'facebook'"
              :loading="accountsLoading"
              :design="bannerDesignById"
              :accounts="facebookAccounts"
            />
          </div>
        </b-col>
        <b-col>
          <div class="screenshot-bg bg-white shadow-sm">
            <div class="share-page-content-img" :style="{ backgroundImage: `url(${previewImage})` }"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TopWizard from '~/components/TopWizard';
import PinterestTab from '~/components/BannerDesigns/Share/PinterestTab';
import FacebookTab from '~/components/BannerDesigns/Share/FacebookTab';

import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import { FETCH_BANNER_DESIGN_BY_ID_QUERY } from '~/graphql/queries';

export default {
  components: { TopWizard, PinterestTab, FacebookTab },

  data() {
    return {
      bannerDesignById: {},
      publishAccounts: [],
      activeStatus: 'pinterest',
      sequence: [
        {
          number: 1,
          name: 'Design',
          to: { name: 'designer.edit' },
        },
        {
          number: 2,
          name: 'Preview & Share',
          to: '',
        },
      ],
    };
  },

  apollo: {
    bannerDesignById: {
      query: FETCH_BANNER_DESIGN_BY_ID_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10) || 0,
        };
      },
    },

    publishAccounts: {
      query: PUBLISH_ACCOUNTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          type: 'social',
        };
      },
    },
  },

  created() {
    // This is important to correctly update query after mutation
    this.$store.dispatch('publishAccount/updateQueryType', 'social');
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    accountsLoading() {
      return this.$apollo.queries.publishAccounts.loading;
    },

    pinterestAccounts() {
      return this.publishAccounts.filter(acct => acct.type.platform === 'pinterest');
    },

    facebookAccounts() {
      return this.publishAccounts.filter(acct => acct.type.platform === 'facebook');
    },

    queryLoading() {
      return this.$apollo.queries.bannerDesignById.loading;
    },

    previewImage() {
      return !this.$apollo.queries.bannerDesignById.loading ? this.bannerDesignById.imageUrl : '';
    },
  },

  methods: {
    goBackHandler() {
      this.$router.push({ name: 'designer.edit' });
    },

    setActiveStatus(status) {
      this.activeStatus = status;
    },

    insertInComposer(type) {
      if (this.queryLoading) {
        return;
      }
      const design = this.bannerDesignById;

      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedImages', [
          {
            url: design.imageUrl,
            thumb: design.thumb,
            type: 'image',
            platform: 'self',
          },
        ]);
      } else if (type === 'blog') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          body: `<div>
              <div class="text-center"><img class="mw-100" src="${design.imageUrl}" alt="" /></div>
              <p><br /></p>
            </div>`,
        });
      }

      this.$store.dispatch('createPost/updatePlatformType', type);
      this.$router.push({ name: 'compose', params: { type, id: 0 } }).catch(() => {});
    },

    downloadDesign() {
      if (this.queryLoading) {
        return;
      }
      window.location.href = `${this.bannerDesignById.imageUrl}?downloadFile=UntitledDesign`;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.design__share {
  padding-top: ($topbar-height * 2.4);
  margin-top: 2.2rem;
  padding: 40px;
  &-tabs {
    background: $white;
    border-radius: 10px;
    padding: 30px;
  }
  .post__nav {
    background: #d8f3dc;
    padding: 8px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 7px;
    border-radius: 10px;
    &-btn {
      background: transparent;
      color: $primary;
      padding: 6px 10px;
      margin: 0 2px;
      &:hover {
        background: $primary;
        color: #d8f3dc;
      }
    }
    &--active {
      background: $primary;
      color: #d8f3dc;
    }
  }
  .share-accounts-scroll {
    overflow: auto;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .simplebar-scrollbar {
      height: 8px;
    }

    .simplebar-track.simplebar-horizontal {
      left: 0;
      height: 10px;
    }

    .simplebar-scrollbar:before {
      border-radius: 5px;
      background: $gray-500;
    }

    .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
      /* When hovered, remove all transitions from drag handle */
      opacity: 1;
      transition: opacity 0s linear;
    }
  }

  .share-accounts-wrap {
    display: flex;

    &::after {
      content: '';
      min-width: 1px;
    }
  }

  .screenshot-bg {
    @include skeleton-animation($gray-200);
  }

  .share-page-content-img {
    width: 100%;
    height: 56vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 70px;
    box-sizing: content-box;
  }
}
</style>
