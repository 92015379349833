<template>
  <div class="bg-white shadow-sm">
    <DesignFabs
      @templateSelect="loadTemplate"
      @backgroundImageSelect="setBackgroundImage"
      @backgroundColorSelect="setCanvasBackgroundColor"
      @textSelect="handleTextSelect"
      @quoteSelect="handleQuoteSelect"
      @frameSelect="handleFrameSelect"
      @uploadSelect="handleUploadSelect"
    />

    <div class="position-relative">
      <div v-if="loading || canvasBusy" class="canvas-busy" :style="{ width: `${width}px`, height: `${height}px` }">
        <spinner variant="primary" />
      </div>
      <canvas ref="canv" id="c" :width="width" :height="height"></canvas>
    </div>

    <DesignToolbar
      v-if="showToolbar"
      :selectedObject="selectedObject"
      :objectType="objectType"
      :fontFamily="fontFamily"
      @close="closeToolbar"
      @delete="deleteObject"
      @editObject="editObject"
      @duplicate="duplicate"
      @moveForward="sendForward"
      @moveBackward="sendBackwards"
      @sendToFront="sendToFront"
      @sendToBack="sendToBack"
    />
  </div>
</template>

<script>
import { fabric } from 'fabric';
import { mapState } from 'vuex';

import fonts from '~/assets/fonts';
import WebFont from 'webfontloader';
import DesignFabs from '~/components/DesignFabs';
import DesignToolbar from '~/components/DesignToolbar';
import { staticPublicPrefix } from '~/utils/helpers.js';

const apiRoot = process.env.VUE_APP_API_ROOT;

export default {
  components: { DesignFabs, DesignToolbar },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    saved: {
      default: '',
    },
    template: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: [String, Number],
    },
    height: {
      type: [String, Number],
    },
  },

  data() {
    return {
      showToolbar: false,
      canvasBusy: false,
      objectType: '',
      fontFamily: '',
    };
  },

  computed: {
    ...mapState({
      quote: state => state.design.quote,
    }),
  },

  watch: {
    saved(saved) {
      if (saved) {
        this.loadSavedDesign(saved);
      }
    },
  },

  created() {
    this.canvas = null;
  },

  mounted() {
    this.initCanvas();
  },

  updated() {
    this.initCanvas();
  },

  methods: {
    initCanvas() {
      if (this.canvas === null) {
        fabric.Object.NUM_FRACTION_DIGITS = 10;

        this.canvas = new fabric.Canvas('c', {
          preserveObjectStacking: 'true',
        });
        this.canvas.selection = false; // disable group selection
        this.canvas.backgroundColor = '#fff';

        this.canvasBusy = true;
        WebFont.load({
          google: {
            families: fonts.filter(font => font.type === 'google').map(font => font.fontFamily),
          },
          custom: {
            families: fonts.filter(font => font.type === 'custom').map(font => font.fontFamily),
            urls: [`${staticPublicPrefix}assets/fonts/faces.css`],
          },
          active: () => {
            // All fonts are loaded
            if (this.quote) {
              this.loadQuoteDesign(this.quote);
            } else if (this.template && this.template.canvasData) {
              this.loadTemplate(this.template);
            } else if (!this.loading && this.saved) {
              this.loadSavedDesign(this.saved);
            } else if (!this.loading && !this.saved) {
              this.loadPlaceholderDesign();
            }

            setTimeout(() => {
              this.canvasBusy = false;
            }, 200);

            this.initEvents();
          },
        });
      }
    },

    loadTemplate(template) {
      this.canvasBusy = true;
      const canvasJSON = JSON.parse(template.canvasData);
      this.canvas.loadFromJSON(canvasJSON, () => {
        this.canvas.renderAll.bind(this.canvas);
        this.canvasBusy = false;

        this.$store.dispatch('design/updateTemplate', null);
      });
    },

    loadSavedDesign(saved) {
      this.canvasBusy = true;
      const canvasJSON = JSON.parse(saved);
      this.canvas.loadFromJSON(canvasJSON, () => {
        this.canvas.renderAll.bind(this.canvas);
        this.canvasBusy = false;

        this.$emit('modified', false);
      });
    },

    loadPlaceholderDesign() {
      const background = `${apiRoot}/designs/backgrounds/bg-10.jpg`;
      const frame = `${apiRoot}/designs/frames/frame-1.png`;

      this.setBackgroundImage({ imageUrl: background });
      this.handleFrameSelect({ imageUrl: frame });

      const textBox = new fabric.Textbox('Add some text here', {
        originX: 'center',
        originY: 'center',
        caching: false,
        width: 350 - 30,
        left: 250,
        top: 215,
        fontSize: 45,
        fontFamily: 'Archivo Black',
        lineHeight: 0.8,
        fixedWidth: 350,
      });

      this.canvas.add(textBox);
    },

    loadQuoteDesign(quote) {
      this.canvas.backgroundColor = '#303f9f';

      const quoteText = new fabric.Textbox(quote.body, {
        width: this.width - 80,
        top: 40,
        left: 40,
        fontSize: 30,
        fontFamily: 'Georgia',
        fill: '#fff',
        fontWeight: 'bold',
        // textAlign: 'center',
        fixedWidth: this.width,
      });

      this.canvas.add(quoteText);

      this.canvas.add(
        new fabric.IText(`- ${quote.author}`, {
          originX: 'center',
          originY: 'center',
          left: Number(this.width) - 120,
          top: Number(this.height) - 50,
          fontSize: quoteText.fontSize,
          fill: '#fff',
          fontWeight: 800,
          fontStyle: 'italic',
        }),
      );

      this.$store.dispatch('design/updateQuote', null);
    },

    initEvents() {
      this.canvas.on('object:added', () => this.$emit('modified', true));
      this.canvas.on('object:removed', () => this.$emit('modified', true));
      this.canvas.on('object:modified', () => this.$emit('modified', true));

      this.canvas.on('mouse:up', e => {
        if (e.target !== null) {
          this.selectObject(e);
        } else {
          this.showToolbar = false;
        }
      });

      this.canvas.on('text:changed', opt => {
        const canvasText = opt.target;
        if (canvasText.width > canvasText.fixedWidth) {
          canvasText.fontSize *= canvasText.fixedWidth / (canvasText.width + 1);
          canvasText.width = canvasText.fixedWidth;
        }

        this.$emit('modified', true);
      });
    },

    selectObject(object) {
      this.showToolbar = true;
      this.selectedObject = object.target;
      this.objectType = this.selectedObject.type;
      if (this.selectedObject.type === 'i-text' || this.selectedObject.type === 'textbox') {
        this.fontFamily = this.selectedObject.fontFamily;
        this.fontSize = this.selectedObject.fontSize;
        this.opacity = this.selectedObject.opacity;
        this.lineHeight = this.selectedObject.lineHeight;
        this.charSpacing = this.selectedObject.charSpacing;
      } else {
        this.opacity = this.selectedObject.opacity;
      }
    },

    async setBackgroundImage({ imageUrl }) {
      this.canvasBusy = true;

      try {
        const img = await this.loadImage(imageUrl);
        this.scaleAndPositionImage(img);
        this.$emit('modified', true);
        console.log('finished');
      } catch (e) {
        console.log('error in canvas ===> ', e);
      }

      this.canvasBusy = false;
      /*
      const bgImg = new fabric.Image();
      bgImg.setSrc(imageUrl, () => {
        this.scaleAndPositionImage(bgImg);

        this.canvasBusy = false;
      });
      */
    },

    scaleAndPositionImage(bgImage) {
      const canvasScale = 1; // *= 1.25 or /= 1.25 to zoom in/out
      const canvasWidth = this.canvas.width * canvasScale;
      const canvasHeight = this.canvas.height * canvasScale;

      const canvasAspect = canvasWidth / canvasHeight;
      const imgAspect = bgImage.width / bgImage.height;
      let left, top, scaleFactor;

      if (canvasAspect >= imgAspect) {
        scaleFactor = canvasWidth / bgImage.width;
        left = 0;
        top = -(bgImage.height * scaleFactor - canvasHeight) / 2;
      } else {
        scaleFactor = canvasHeight / bgImage.height;
        top = 0;
        left = -(bgImage.width * scaleFactor - canvasWidth) / 2;
      }

      this.canvas.setBackgroundImage(bgImage, this.canvas.renderAll.bind(this.canvas), {
        top: top,
        left: left,
        originX: 'left',
        originY: 'top',
        scaleX: scaleFactor,
        scaleY: scaleFactor,
      });
      this.canvas.renderAll();
    },

    async handleFrameSelect({ imageUrl }) {
      let canvasWidth = this.canvas.width;
      let canvasHeight = this.canvas.height;
      this.canvasBusy = true;

      const img = await this.loadImage(imageUrl);
      img.set({
        top: 0,
        left: 0,
      });

      if (img.width < img.height) {
        img.scaleToHeight(canvasHeight);
      } else {
        img.scaleToWidth(canvasWidth);
      }

      this.canvas.add(img);
      img.center();
      this.canvas.sendBackwards(img);
      this.canvas.renderAll();

      this.canvasBusy = false;

      /*
      let frameImg = new fabric.Image();
      frameImg.setSrc(imageUrl, () => {
        frameImg.set({
          top: 0,
          left: 0,
        });

        if (frameImg.width < frameImg.height) {
          frameImg.scaleToHeight(canvasHeight);
        } else {
          frameImg.scaleToWidth(canvasWidth);
        }

        this.canvas.add(frameImg);
        frameImg.center();
        this.canvas.sendBackwards(frameImg);
        this.canvas.renderAll();

        this.canvasBusy = false;
      });
      */
    },

    async handleUploadSelect({ url }) {
      this.canvasBusy = true;

      const img = await this.loadImage(url);
      if (img.width > this.canvas.width) {
        img.scaleToWidth(this.canvas.width - 100);
      }

      this.canvas.add(img);
      img.center();

      this.canvas.renderAll();

      this.canvasBusy = false;

      /*
      let img = new fabric.Image();
      img.setSrc(url, () => {
        if (img.width > this.canvas.width) {
          img.scaleToWidth(this.canvas.width - 100);
        }

        this.canvas.add(img);
        img.center();

        this.canvas.renderAll();

        this.canvasBusy = false;
      });
      */
    },

    handleTextSelect(text) {
      const iText4 = new fabric.IText(text.name, {
        left: 225,
        top: 215,
        originX: 'center',
        originY: 'center',
        fontFamily: text.fontFamily,

        caching: false,
      });

      this.canvas.add(iText4);
      this.canvas.renderAll();
    },

    handleQuoteSelect(quote) {
      const quoteText = new fabric.Textbox(quote.body, {
        width: this.width - 30,
        top: 20,
        left: 10,
        fontSize: 25,
        textAlign: 'center',
        fixedWidth: this.width,
      });

      this.canvas.add(quoteText);

      this.canvas.add(
        new fabric.IText(`- ${quote.author}`, {
          originX: 'center',
          originY: 'center',
          left: Number(this.width) - 120,
          top: Number(this.height) - 50,
          fontSize: quoteText.fontSize,
          fontWeight: 800,
          fontStyle: 'italic',
        }),
      );
    },

    loadImage(imgSrc) {
      return new Promise((resolve, reject) => {
        const imgObj = new Image();

        imgObj.crossOrigin = 'Anonymous';
        imgObj.onload = function() {
          const tempCanvas = document.createElement('canvas');
          const tempCtx = tempCanvas.getContext('2d');
          const height = (tempCanvas.height = this.naturalHeight);
          const width = (tempCanvas.width = this.naturalWidth);
          console.log('reached here 0');
          tempCtx.drawImage(this, 0, 0);
          const dataURL = tempCanvas.toDataURL();
          console.log('reached here');

          fabric.Image.fromURL(dataURL, function(img) {
            img.set({
              width: width,
              height: height,
            });

            console.log('reached here x2');
            resolve(img);
          });
        };

        imgObj.onerror = function(e) {
          reject({ e, stack: 'from trying to load image' });
        };

        // const timestamp = Date.now();
        // const parsedUrl = new URL(imgSrc);
        // if (parsedUrl.search && parsedUrl.search !== '?') {
        //   imgSrc = `${imgSrc}&timestamp=${timestamp}`;
        // } else {
        //   imgSrc = `${imgSrc}?timestamp=${timestamp}`;
        // }

        imgObj.src = imgSrc;
      });
    },

    async addImage(image) {
      const img = await this.loadImage(image.imageUrl);
      this.canvas.add(img);
    },

    duplicate() {
      let object = this.canvas.getActiveObject();

      object.clone(cloned => {
        cloned.set('top', object.top + 10).set('left', object.left + 10);
        this.canvas.add(cloned);
        this.canvas.renderAll();

        this.canvas.setActiveObject(this.canvas.getObjects()[this.canvas.getObjects().length - 1]);
      });
    },

    sendForward() {
      let object = this.canvas.getActiveObject();
      this.canvas.bringForward(object);
    },

    sendBackwards() {
      let object = this.canvas.getActiveObject();
      this.canvas.sendBackwards(object);
    },

    sendToFront() {
      let object = this.canvas.getActiveObject();
      this.canvas.bringToFront(object);
    },

    sendToBack() {
      let object = this.canvas.getActiveObject();
      this.canvas.sendToBack(object);
    },

    setCanvasBackgroundColor(color) {
      this.canvas.backgroundImage = 0;
      this.canvas.backgroundColor = color;
      this.canvas.renderAll();
    },

    sizeSelect(size) {
      this.canvasSize = size.name;
      let newHeight = 435 * size.aspectRatio;
      this.canvas.setHeight(newHeight);
      this.canvas.calcOffset();
    },

    deleteObject() {
      let object = this.canvas.getActiveObject();
      this.canvas.remove(object);
      this.showToolbar = false;
      this.canvas.renderAll();
    },

    handleFontSelect(font) {
      this.editObject('fontFamily', font.fontFamily);
      this.fontFamily = font.fontFamily;
    },

    chooseOptions(option) {
      this.showToolbar = true;
      this.choosenOption = option;
    },

    closeToolbar() {
      this.showToolbar = false;
      this.choosenOption = '';
    },

    closeEditObjectCard() {
      this.showToolbar = false;
    },

    editObject(property, value) {
      if (property === 'textAlign') {
        this.alignment = value;
      }
      this.canvas.getActiveObject().set(property, value);
      this.canvas.renderAll();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.canvas-busy {
  background-color: rgba($light, 0.7);
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
