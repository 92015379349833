<template>
  <div ref="gifsScroll" class="panel-scroll gif-panel">
    <div class="panel-body">
      <h6 class="mb-3">Add Texts</h6>

      <div class="mb-3 text-container" v-for="text in texts" :key="text.fontFamily">
        <span
          @click="handleAddText(text)"
          :style="{
            fontFamily: text.fontFamily,
            fontSize: text.fontSize,
            lineHeight: text.lineHeight,
            letterSpacing: text.letterSpacing,
          }"
          >{{ text.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import texts from '~/utils/texts';

export default {
  name: 'editor-fabs-gifs-panel',

  data() {
    return {
      gifSearch: [],
      offset: 1,
      gifKeywords: '',

      apiRoot: process.env.VUE_APP_API_ROOT,
    };
  },

  created() {
    this.texts = texts;
  },

  methods: {
    handleAddText(text) {
      this.$emit('select', text);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.text-container {
  color: #162d3d;
  &:hover {
    cursor: pointer;
    color: $secondary;
  }
}
</style>
