<template>
  <div ref="imagesScroll" class="panel-scroll backgrounds-panel">
    <div class="panel-body">
      <h6 class="mb-3 mt-2">Colors</h6>
      <div class="d-flex flex-wrap mb-4">
        <div v-for="color in colorArray" :key="color" class="color-container mr-1">
          <div @click="handleColorSelect(color)" class="color shadow-sm" :style="{ backgroundColor: color }"></div>
        </div>
      </div>

      <hr />
      <h6 class="mb-3 mt-2">Images</h6>
      <div class="images-wrap mt-4">
        <div class="images-grid">
          <div
            v-for="background in backgrounds"
            :key="background.id"
            class="image-item bg"
            @click="handleBackgroundSelect(background)"
          >
            <div :style="{ backgroundImage: `url(${background.thumb})` }" class="image-img"></div>
          </div>
        </div>
      </div>

      <hr />

      <h6 class="mb-3 mt-2">Find Images</h6>
      <b-form-input
        @keyup.enter="refetchImages"
        @change="handleImageKeywordChange"
        class="bg-light shadow-sm border-0"
        size="sm"
        autocomplete="off"
        placeholder="Enter a keyword and hit enter"
      />

      <div class="images-wrap mt-4">
        <div v-if="hasImages" class="images-grid">
          <div
            v-for="image in images.contents"
            :key="image.id"
            class="image-item"
            @click="handleBackgroundSelect(image)"
          >
            <div :style="{ backgroundImage: `url(${image.thumb})` }" class="image-img"></div>
          </div>
        </div>
        <div v-else-if="!loadingMore && !$apollo.queries.images.loading && !hasImages" class="text-center mt-4">
          Nothing found! <br />
          Try another keyword.
        </div>
        <div v-if="$apollo.queries.images.loading">
          <skeleton-screens
            class="images-grid skeleton"
            itemClass="image-item"
            times="4"
            width="135px"
            height="135px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorArray from '~/utils/colors';
import { STOCK_IMAGE_SEARCH_QUERY } from '~/graphql/queries';
import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';

const apiRoot = process.env.VUE_APP_API_ROOT;

export default {
  name: 'editor-fabs-backgrounds-panel',

  mixins: [InfiniteScrollMixin],

  data() {
    return {
      images: [],
      offset: 1,
      imageKeywords: '',
      colorArray,
      backgrounds: [
        {
          id: 10,
          imageUrl: `${apiRoot}/designs/backgrounds/bg-10.jpg`,
          thumb: `${apiRoot}/designs/backgrounds/bg-10_thumb.jpg`,
        },
        {
          id: 1,
          imageUrl: `${apiRoot}/designs/backgrounds/bg-1.jpg`,
          thumb: `${apiRoot}/designs/backgrounds/bg-1_thumb.jpg`,
        },
        {
          id: 2,
          imageUrl: `${apiRoot}/designs/backgrounds/bg-2.jpg`,
          thumb: `${apiRoot}/designs/backgrounds/bg-2_thumb.jpg`,
        },
        {
          id: 3,
          imageUrl: `${apiRoot}/designs/backgrounds/bg-3.jpg`,
          thumb: `${apiRoot}/designs/backgrounds/bg-3_thumb.jpg`,
        },
        {
          id: 5,
          imageUrl: `${apiRoot}/designs/backgrounds/bg-5.jpg`,
          thumb: `${apiRoot}/designs/backgrounds/bg-5_thumb.jpg`,
        },
        {
          id: 11,
          imageUrl: `${apiRoot}/designs/backgrounds/bg-11.jpg`,
          thumb: `${apiRoot}/designs/backgrounds/bg-11_thumb.jpg`,
        },
      ],
    };
  },

  apollo: {
    images: {
      query: STOCK_IMAGE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.imageKeywords,
          offset: this.offset,
          limit: 10,
        };
      },
    },
  },

  computed: {
    hasImages() {
      return this.images && this.images.contents && this.images.contents.length > 0;
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (
        isRockBottom &&
        this.images &&
        this.images.contents &&
        this.images.contents.length &&
        this.loadMoreEnabled &&
        !this.loadingMore
      ) {
        this.loadMoreImages();
      }
    },
  },

  mounted() {
    this.$refs.imagesScroll.addEventListener('scroll', this.scrollEventCallback);
  },

  beforeDestroy() {
    this.$refs.imagesScroll.removeEventListener('scroll', this.scrollEventCallback);
  },

  methods: {
    scrollEventCallback() {
      this.isRockBottom = this.isBottomOf(this.$refs.imagesScroll);
    },

    handleColorSelect(color) {
      this.$emit('colorSelect', color);
    },

    handleBackgroundSelect(background) {
      this.$emit('imageSelect', background);
    },

    handleImageKeywordChange(input) {
      this.imageKeywords = input;
    },

    refetchImages(event) {
      event.preventDefault();

      this.loadMoreEnabled = true;
      this.images.contents = [];

      this.$apollo.queries.images.refetch({
        keywords: this.imageKeywords,
      });
    },

    async loadMoreImages() {
      this.offset = this.offset + 1;
      this.loadingMore = true;

      try {
        // Fetch more data and transform the original result
        await this.$apollo.queries.images.fetchMore({
          // New variables
          variables: {
            keywords: this.imageKeywords,
            offset: this.offset,
            limit: 10,
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newContents = fetchMoreResult.images.contents;
            const filtered = newContents.filter(img => {
              const existingIds = this.images.contents.map(exImg => exImg.id);
              return !existingIds.includes(img.id);
            });

            if (filtered.length < 1) {
              this.loadMoreEnabled = false;
              return {
                ...previousResult,
              };
            }

            const hasMore = fetchMoreResult.images.hasMore;
            const total = fetchMoreResult.images.total;

            this.loadMoreEnabled = hasMore;

            return {
              images: {
                __typename: previousResult.images.__typename,
                // Merging the tag list
                contents: [...previousResult.images.contents, ...filtered],
                total,
                hasMore,
              },
            };
          },
        });
      } catch (e) {
        console.warn(e.message);
      }

      this.loadingMore = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.backgrounds-panel {
  .color-container {
    height: 39px;
    width: 39px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    &:hover {
      border: 1px solid #daeffe;
      cursor: pointer;
      border-radius: 50%;
    }

    .color {
      height: 33px;
      width: 33px;
      border-radius: 50%;
      border: 1px solid $gray-200;
    }
  }
  .images-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .image-item {
      width: 150px;
      height: 150px;
      margin-bottom: 15px;
      @include skeleton-animation(lightgray);

      .image-img {
        width: 100%;
        height: 100%;
        background-size: cover;
        cursor: pointer;
      }

      &.bg {
        width: 100px;
        height: 100px;

        .image-img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.skeleton {
      .image-img {
        cursor: default;
      }
    }
  }
}
</style>
