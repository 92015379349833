<template>
  <div class="mt-4">
    <b-form @submit.prevent="handleFormSubmit" @keydown="form.onKeydown($event)">
      <p>Select a Pinterest board where your post will be shared.</p>

      <share-accounts-skeleton v-if="loading" :itemCount="2" class="mb-3" />
      <div v-else-if="accounts.length && !loading">
        <simplebar data-simplebar-auto-hide="false" class="share-accounts-scroll">
          <div class="share-accounts-wrap">
            <AddShareAccount tip="Connect a new Pinterest account" @click="connectPinterest" />
            <ShareAccount
              v-for="account in accounts"
              :key="account.id"
              :account="account"
              :active="form.accountId == account.id"
              :disabled="!account.type.isPublishable"
              @click="handleToggleAccount(account.id)"
            />
          </div>
          <has-error :form="form" field="accountId" />
        </simplebar>
      </div>
      <div v-else-if="accounts.length < 1 && !loading" class="pb-3">
        <b-button
          variant="danger"
          class="btn-pinterest rounded-lg px-3 py-2"
          size="sm"
          :disabled="connectingPinterest"
          @click="connectPinterest"
        >
          <b-spinner v-if="connectingPinterest" class="mr-1" small />
          <svg-icon v-else name="pinterest" size="md" class="mr-1" />
          Connect a Pinterest account
        </b-button>
      </div>

      <b-form-group label-for="pinterest-caption">
        <b-form-textarea
          v-model="form.caption"
          id="pinterest-caption"
          name="caption"
          placeholder="Add a short caption"
          class="rounded-lg outline-secondary"
          rows="6"
          no-resize
        />
        <has-error :form="form" field="caption" />
      </b-form-group>

      <div class="mt-5">
        <b-button type="submit" variant="primary" class="px-4 py-2 rounded-pill" :disabled="disabled">
          <b-spinner v-if="sharing" class="mr-1" small />
          <svg-icon v-else name="plane-alt" class="mr-1" size="md" />
          Share Now
        </b-button>
      </div>
    </b-form>

    <b-modal
      id="PinterestPostSharedModal"
      content-class="rounded-sm text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHidePostSharedModal"
    >
      <template v-slot:default="{ hide }">
        <h4 class="font-weight-bold mb-3">All Set!</h4>
        <p class="m-1">Your post is now being processed. You will get a notification when it is published.</p>
        <div class="mt-4">
          <b-button variant="outline-primary" class="px-4 mr-2" size="sm" @click="hide">Okay</b-button>
          <b-button :to="{ name: 'post.view', params: { id: sharedPost.id } }" class="px-3" size="sm">
            View Post
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

import ShareAccount from '~/components/BannerDesigns/ShareAccount';
import AddShareAccount from '~/components/BannerDesigns/AddShareAccount';
import ShareAccountsSkeleton from '~/components/BannerDesigns/ShareAccountSkeleton';

import { SHARE_DESIGN_MUTATION } from '~/graphql/mutations';

export default {
  components: { simplebar, AddShareAccount, ShareAccount, ShareAccountsSkeleton },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    design: {
      type: Object,
      required: true,
    },

    accounts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_ROOT,
      connectingPinterest: false,
      form: new Form({
        accountId: 0,
        caption: '',
      }),
      sharing: false,
      sharedPost: null,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),

    disabled() {
      return !this.form.accountId || !this.form.caption || this.loading || this.sharing;
    },
  },

  methods: {
    connectPinterest() {
      this.connectingPinterest = true;
      window.location = `${this.apiUrl}/account/ConnectWithPinterest?workspace=${
        this.workspace.slug
      }&next=${encodeURIComponent(window.location.href)}`;
    },

    handleToggleAccount(accountId) {
      if (accountId === this.form.accountId) {
        return (this.form.accountId = null);
      }

      this.form.accountId = accountId;
      this.form.errors.set('accountId', []);
    },

    handleFormSubmit() {
      if (!this.form.accountId) {
        this.form.errors.set('accountId', ['Please Select an account']);
      }
      if (!this.form.caption) {
        this.form.errors.set('caption', ['Please add a caption for your post']);
      }

      this.sharing = true;

      this.$apollo
        .mutate({
          mutation: SHARE_DESIGN_MUTATION,
          variables: {
            workspace: parseInt(this.workspace.id),
            design: parseInt(this.design.id),
            platform: 'pinterest',
            ...this.form.data(),
          },
        })
        .then(({ data }) => {
          this.sharing = false;

          this.sharedPost = data.shareDesign;

          this.$bvModal.show('PinterestPostSharedModal');
        })
        .catch(() => {
          this.sharing = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHidePostSharedModal() {
      this.form.reset();
    },
  },
};
</script>
