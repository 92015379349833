<template>
  <div>
    <MyDesigns editRoute="designer.edit" routeParam="id" />
  </div>
</template>

<script>
import MyDesigns from '~/components/BannerDesigns/MyDesigns';

export default {
  components: {
    MyDesigns,
  },
};
</script>
