<template>
  <div ref="templatesScroll" class="panel-scroll templates-panel">
    <div class="panel-body">
      <template v-if="viewingCategory">
        <div class="template-block-wrap">
          <div class="d-flex justify-content-between mb-2">
            <div class="d-flex align-items-center">
              <b-button variant="link" size="sm" class="text-muted pl-0 mr-1" @click="clearViewingCategory">
                <svg-icon name="arrow-left" />
              </b-button>
              <h6 class="font-weight-bold mb-1">{{ viewingCategory.title }}</h6>
            </div>
          </div>
          <div v-if="loadingCategoryTemplates">
            <skeleton-screens
              class="template-grid skeleton"
              itemClass="template-item"
              times="4"
              width="134px"
              height="134px"
            />
          </div>
          <div v-else class="templates-wrap">
            <div v-if="categoryTemplates.length" class="template-grid">
              <div
                v-for="template in categoryTemplates"
                :key="template.id"
                class="template-item"
                @click="handleTemplateSelect(template)"
              >
                <div :style="{ backgroundImage: `url(${template.thumb})` }" class="template-img"></div>
              </div>
            </div>
            <div v-else-if="categoryTemplates.templates.length < 1" class="shadow-sm text-center py-3 mb-2">
              No templates in this category
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="hasCats">
          <div v-for="category in designTemplatesCategories.categories" :key="category.id" class="template-block-wrap">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-button
                  variant="link"
                  type="button"
                  class="template-category-name px-0"
                  @click="viewCategory(category)"
                >
                  {{ category.title }}
                </b-button>
                <b-button
                  v-if="can(user, 'bypass-subscription')"
                  v-b-tooltip.hover
                  title="Edit category"
                  variant="link"
                  type="button"
                  class="edit-btn ml-2 px-0"
                  @click="editCategory(category)"
                >
                  <svg-icon name="pencil" />
                </b-button>
              </div>
              <b-button variant="link" type="button" class="view-all-categories px-0" @click="viewCategory(category)">
                See all
              </b-button>
            </div>
            <div class="templates-wrap">
              <div v-if="category.templates.length" class="template-grid">
                <div
                  v-for="template in category.templates"
                  :key="template.id"
                  class="template-item"
                  @click="handleTemplateSelect(template)"
                >
                  <div :style="{ backgroundImage: `url(${template.thumb})` }" class="template-img"></div>
                </div>
              </div>
              <div v-else-if="category.templates.length < 1" class="shadow-sm text-center py-3 mb-2">
                No templates in this category
              </div>
            </div>
          </div>
        </template>
        <div
          v-else-if="!$apollo.queries.designTemplatesCategories.loading && !hasCats"
          class="shadow-sm p-4 text-center"
        >
          No Templates Yet
        </div>
        <div v-if="$apollo.queries.designTemplatesCategories.loading">
          <skeleton-screens
            class="template-grid skeleton"
            itemClass="template-item"
            times="8"
            width="134px"
            height="134px"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { can } from '~/utils/helpers';

import { DESIGN_TEMPLATE_CATEGORIES_QUERY, DESIGN_TEMPLATES_QUERY } from '~/graphql/queries';

export default {
  name: 'editor-fabs-templates-panel',

  data() {
    return {
      images: [],
      offset: 1,
      imageKeywords: '',

      viewingCategory: null,

      loadingCategoryTemplates: false,
      categoryTemplates: [],
    };
  },

  apollo: {
    designTemplatesCategories: {
      query: DESIGN_TEMPLATE_CATEGORIES_QUERY,
      variables() {
        return {
          limit: 50,
        };
      },
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    hasCats() {
      return (
        this.designTemplatesCategories &&
        this.designTemplatesCategories.categories &&
        this.designTemplatesCategories.categories.length > 0
      );
    },
  },

  methods: {
    can,

    viewCategory(category) {
      this.viewingCategory = category;

      this.loadingCategoryTemplates = true;
      this.$apollo
        .query({
          query: DESIGN_TEMPLATES_QUERY,
          variables: {
            category: category.id,
          },
        })
        .then(({ data: { designTemplates } }) => {
          this.loadingCategoryTemplates = false;

          this.categoryTemplates = designTemplates.templates;
        })
        .catch(() => {
          this.loadingCategoryTemplates = false;

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    editCategory(category) {
      this.$store.dispatch('design/invokeCategory', category);
      this.$bvModal.show('NewCategoryModal');
    },

    clearViewingCategory() {
      this.viewingCategory = null;
      this.categoryTemplates = [];
    },

    handleTemplateKeywordChange(input) {
      this.imageKeywords = input;
    },

    handleTemplateSelect(template) {
      this.$emit('templateSelect', template);
    },

    refetchImages(event) {
      event.preventDefault();

      this.loadMoreEnabled = true;
      this.designTemplatesCategories.categories = [];

      this.$apollo.queries.designTemplatesCategories.refetch({
        keywords: this.imageKeywords,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.templates-panel {
  .template-block-wrap {
    max-width: 280px;
    margin: auto;
    color: $secondary;

    .template-category-name {
      font-size: 1rem;
      color: $secondary;
      font-weight: bold;
      width: 80%;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .edit-btn {
      opacity: 0;
    }

    &:hover {
      .edit-btn {
        opacity: 1;
      }
    }
  }

  .view-all-categories {
    font-size: 0.8rem;
    color: $gray-400;
    font-weight: bold;
  }

  .template-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .template-item {
      // width: 137px;
      // height: 137px;
      margin-bottom: 15px;
      @include skeleton-animation(lightgray);
      border: 1px solid $gray-300;

      .template-img {
        width: 134px;
        height: 134px;
        background-size: cover;
        cursor: pointer;
      }

      &.bg {
        width: 85px;
        height: 85px;

        .template-img {
          width: 85px;
          height: 85px;
        }
      }
    }

    &.skeleton {
      .template-img {
        cursor: default;
      }
    }
  }
}
</style>
