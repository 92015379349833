<template>
  <div class="skeleton-wrap d-flex flex-wrap">
    <div
      v-for="item in items"
      :key="item + 1"
      class="share-account-skeleton p-3 rounded-sm"
      :class="{ [itemClass]: itemClass }"
    >
      <skeleton-screen width="30px" height="30px" class="sas rounded-circle mr-1" />
      <skeleton-screen width="50%" height="17px" class="sas" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'share-accounts-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 8,
    },

    itemClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.skeleton-wrap {
  .share-account-skeleton {
    display: flex;
    align-content: center;
    padding: 20px;
    width: 225px;
    height: 75px;
    margin-right: 10px;
    border: 1px solid $gray-200;
    box-shadow: 0 1px 5px $gray-200;

    .sas {
      align-self: center;
    }
  }
}
</style>
