<template>
  <div class="shadow-sm design-toolbar">
    <div class="text-settings" v-if="objectType === 'i-text' || objectType === 'textbox'">
      <div class="border-bottom p-3 header">
        <span class="header-title">Edit Text</span>
        <b-button variant="clear" @click="$emit('close')" class="header-button float-right">
          <svg-icon name="close" size="sm" class="text-white" />
        </b-button>
      </div>
      <div class="toolbar-scroll">
        <div class="border-bottom m-0 p-4 settings-popup">
          <label class="font-weight-light">Font</label>
          <br />
          <FontSelect :selected="fontFamily" @select="handleFontSelect" />
        </div>
        <div class="p-4 border-bottom">
          <label class="font-weight-light">Font Size (px)</label>
          <br />
          <b-form-input
            class="w-75"
            v-model="selectedObject.fontSize"
            @input="$emit('editObject', 'fontSize', selectedObject.fontSize)"
            min="16"
            max="100"
            step="1"
            type="range"
          />
          <div class="font-size-input ml-2">
            <input
              type="text"
              maxlength="8"
              max="100"
              step="1"
              @input="$emit('editObject', 'fontSize', selectedObject.fontSize)"
              v-model="selectedObject.fontSize"
            />
          </div>
        </div>
        <div class="p-4 border-bottom">
          <label class="font-weight-light">Opacity & Color</label>
          <br />
          <b-form-input
            v-model="selectedObject.opacity"
            @input="$emit('editObject', 'opacity', selectedObject.opacity)"
            min="0"
            max="1"
            step="0.01"
            class="w-75"
            type="range"
          />
          <div id="addTextColor" class="text-color-container float-right">
            <text-color :currentColor="selectedObject.fill" />
          </div>
          <b-popover container="addTextColor" :show.sync="isShowTextColorPopover" target="addTextColor">
            <div class="d-flex justify-content-between" slot="title">
              Pick a color
              <b-button variant="clear" @click="closeTextColorPopover" class="panel-header-button">
                <svg-icon name="close" size="sm" class="text-white" />
              </b-button>
            </div>

            <ColorPicker v-model="textcolor" @input="$emit('editObject', 'fill', textcolor.hex)" />

            <div class="text-right mt-3">
              <b-button @click="closeTextColorPopover" type="reset" variant="clear" size="sm">Close</b-button>
            </div>
          </b-popover>
        </div>
        <div class="p-4 border-bottom">
          <label class="font-weight-light">Line Height</label>
          <br />
          <b-form-input
            v-model="selectedObject.lineHeight"
            @input="$emit('editObject', 'lineHeight', selectedObject.lineHeight)"
            min="0.5"
            step="0.2"
            max="5"
            class="w-75"
            type="range"
          />
          <div class="font-size-input">
            <input
              type="text"
              maxlength="8"
              step="1"
              v-model="selectedObject.lineHeight"
              @input="$emit('editObject', 'lineHeight', selectedObject.lineHeight)"
            />
          </div>
        </div>
        <div class="p-4">
          <label class="font-weight-light">Alignment</label>
          <br />
          <div class="row p-2">
            <div
              @click="$emit('editObject', 'textAlign', 'left')"
              :class="{ highlighted: selectedObject.alignment === 'left' }"
              class="text-color-container mr-2"
            >
              <sprite-icon style="color:#000000;" icon="left-align" />
            </div>
            <div
              @click="$emit('editObject', 'textAlign', 'center')"
              :class="{ highlighted: selectedObject.alignment === 'center' }"
              class="text-color-container mr-2"
            >
              <sprite-icon style="color:#000000;" icon="center" />
            </div>
            <div
              @click="$emit('editObject', 'textAlign', 'right')"
              :class="{ highlighted: selectedObject.alignment === 'right' }"
              class="text-color-container mr-2"
            >
              <sprite-icon style="color:#000000;" icon="right-align" />
            </div>
            <div
              @click="$emit('editObject', 'textAlign', 'justify')"
              :class="{ highlighted: selectedObject.alignment === 'justify' }"
              class="text-color-container mr-2"
            >
              <sprite-icon style="color:#000000;" icon="justify" />
            </div>
          </div>
        </div>
      </div>
      <DesignElementToolbar
        @delete="$emit('delete')"
        @duplicate="$emit('duplicate')"
        @moveForward="$emit('moveForward')"
        @moveBackward="$emit('moveBackward')"
        @sendToFront="$emit('sendToFront')"
        @sendToBack="$emit('sendToBack')"
      />
    </div>
    <div class="object-settings" v-else>
      <div class="border-bottom p-3 header">
        <span class="header-title">Edit Element</span>
        <b-button variant="clear" @click="$emit('close')" class="header-button float-right">
          <svg-icon name="close" size="sm" class="text-white" />
        </b-button>
      </div>
      <div class="p-3">
        <label class="font-weight-light">Opacity</label>
        <br />
        <b-form-input
          v-model="selectedObject.opacity"
          @input="$emit('editObject', 'opacity', selectedObject.opacity)"
          min="0"
          max="1"
          step="0.1"
          class="w-75"
          type="range"
        />
        <span class="float-right" style="margin-top: -2px">{{ selectedObject.opacity * 100 + '%' }}</span>
      </div>
      <DesignElementToolbar
        @delete="$emit('delete')"
        @duplicate="$emit('duplicate')"
        @moveForward="$emit('moveForward')"
        @moveBackward="$emit('moveBackward')"
        @sendToFront="$emit('sendToFront')"
        @sendToBack="$emit('sendToBack')"
      />
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color';
import DesignElementToolbar from '~/components/DesignElementToolbar';
import FontSelect from '~/components/RichTextEditor/components/FontSelect';

var textcolor = {
  hex: '#194d33',
  hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
  hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
  rgba: { r: 25, g: 77, b: 51, a: 1 },
  a: 1,
};

export default {
  components: {
    ColorPicker: Sketch,
    DesignElementToolbar,
    FontSelect,
  },

  props: {
    selectedObject: {
      default: () => ({}),
    },
    fontFamily: {
      type: String,
      default: 'Montserrat',
    },
    objectType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      textcolor,
      isShowTextColorPopover: false,
    };
  },

  methods: {
    closeTextColorPopover() {
      this.isShowTextColorPopover = false;
    },
    handleFontSelect(font) {
      this.$emit('editObject', 'fontFamily', font.fontFamily);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.design-toolbar {
  background-color: $white;
  position: fixed;
  right: 80px;
  top: 150px;
  border-radius: 8px;

  .header {
    background-color: $primary;
    height: 54px;
    min-height: 54px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;

    .header-title {
      font-size: 16px;
      line-height: 54px;
      overflow: hidden;
      color: #fff;
      max-width: 75%;
      white-space: nowrap;
    }
    .header-button {
      svg {
        fill: $secondary;
      }
    }
  }

  .settings-popup > .pop .combo {
    max-height: 360px;
  }

  .toolbar-scroll {
    overflow-y: auto;
    max-height: 350px;
    margin-right: 2px;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background: $white;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: rgba($primary, 0.7);
      cursor: pointer;
      height: 20px;
      display: none;
    }
  }

  &:hover {
    .toolbar-scroll {
      &::-webkit-scrollbar-thumb {
        display: block;
      }
    }
  }

  .color-container {
    height: 39px;
    width: 39px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-pack: center;
    border-radius: 100%;

    &:hover {
      border: 1px solid #daeffe;
      cursor: pointer;
      border-radius: 100%;
    }
  }

  .text-color {
    height: 33px;
    width: 33px;
    border-radius: 10%;
    border: 1px solid #f0f4f7;
  }

  .text-color-container {
    margin-top: -10px;
    height: 39px;
    width: 39px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-pack: center;
    border-radius: 10%;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #daeffe;
      cursor: pointer;
      border-radius: 10%;
    }
  }

  .highlighted {
    border: 1px solid #66c;
  }

  .font-size-input {
    border-radius: 8px;
    float: right;
    border: 1px solid transparent;
    width: 50px;
    margin-top: -6px;
    position: relative;
    text-align: left;
    display: inline-block;

    &:hover {
      background-color: rgba($primary, 0.2);
      cursor: pointer;
    }

    input {
      text-align: right;
      background-color: transparent;

      border: none;
      outline: none;
      text-align: center;
      font-size: 18px;
      height: 28px;
      line-height: 36px;
      padding: 0 7px;
      width: 100%;
      display: inline-block;
      color: #162d3d;
      cursor: pointer;

      &:focus {
        border: 1px solid $primary;
        border-radius: 8px;
      }
    }
  }

  .popover-header {
    background-color: $primary;
    color: $white;
    $offset-border-width: calc(#{$border-radius-sm} - #{$popover-border-width});
    @include border-top-radius($offset-border-width);
  }

  .popover-body {
    .vc-sketch {
      width: 220px;
      padding: 0;
      background: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .theCanvas {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .search-input {
    border-radius: 20px 0 0 20px;
    padding: 8px 25px;
    background-color: #eef5f9;
    border: 1px solid #eef5f9;
    margin-left: auto;
    min-width: 50%;
    outline: 0;
  }

  .text-settings {
    width: 300px;
  }

  .custom-select {
    &:focus {
      border: none;
    }
  }

  .text-container {
    color: #162d3d;
    &:hover {
      cursor: pointer;
      color: #32536a;
    }
  }

  .object-toolbar {
    position: fixed;
    right: 8.3%;
    top: 19.7%;
    height: 435px;
    z-index: 1000;

    .settings-popup {
      .pop {
        .combo {
          height: 330px;
        }
      }
    }

    .tooltip {
      .arrow::before {
        border-top-color: $white;
        z-index: 1070;
      }

      .tooltip-inner {
        background-color: $white;
        box-shadow: 0 0 14px 0 rgba(22, 45, 61, 0.36);
        font-size: 14px;
        position: relative;
        color: #2b5672;
        border-radius: 8px;
        padding: 12px;
        white-space: nowrap;

        &:empty {
          display: none;
        }
      }
    }
  }
}
</style>
