<template>
  <section class="mt-2 mb-5 px-5">
    <b-row class="draft__top">
      <b-col cols="6" class="draft__top-text">
        <h4>Image Designs</h4>
        <p>Choose a stunning template and customize it to stand out on social media.</p>
      </b-col>
      <b-col class="draft__top-action-btn">
        <b-button variant="light" size="lg" class="rounded px-3 mx-1" :to="{ name: 'designer.designs' }">
          My Designs
        </b-button>
        <b-button
          :to="{ name: editRoute, params: { [routeParam]: 0 } }"
          variant="primary"
          size="lg"
          class="rounded px-3 mx-1"
        >
          Create New
        </b-button>
      </b-col>
    </b-row>
    <b-row class="design__template my-3">
      <b-col class="d-flex align-items-center">
        <div class="p-2 text-primary">
          <h5 class="font-weight-bold">Design Templates</h5>
          <p>Showcase a product, promote a sale, share an inspirational quote and more.</p>
          <b-button
            :to="{ name: editRoute, params: { [routeParam]: 0 } }"
            variant="success"
            class="px-4 py-2 rounded text-secondary"
            >Create New</b-button
          >
        </div>
      </b-col>
      <b-col cols="8" class="d-flex">
        <template class="featured" v-if="queryLoading">
          <skeleton-screens
            v-for="i in 3"
            :key="i"
            class="featured"
            itemClass="rounded mb-4 mr-3"
            width="255px"
            height="255px"
          />
        </template>
        <template v-else>
          <div
            v-for="featured in featuredTemplates"
            :key="featured.id"
            class="design-template-item featured"
            :style="{ backgroundImage: `url(${featured.thumb}` }"
          >
            <div class="design-overlay">
              <div class="overlay-btn">
                <b-button
                  size="sm"
                  variant="primary"
                  class="rounded border border-secondary mr-1 px-4"
                  @click="useTemplate(featured)"
                >
                  Use template
                </b-button>
                <b-button
                  v-if="can(user, 'bypass-subscription')"
                  size="sm"
                  variant="primary"
                  class="rounded border border-secondary ml-1"
                  @click="invokeDeleteTemplate(featured)"
                >
                  <svg-icon name="trash" />
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </b-col>
    </b-row>
    <div class="text-primary">
      <h5 class="font-weight-bold my-4">Explore All Templates</h5>
      <b-row cols="4" v-if="queryLoading">
        <b-col v-for="i in 12" :key="i">
          <skeleton-screens
            class="d-flex justify-content-between flex-wrap"
            itemClass="rounded mb-4"
            width="100%"
            height="255px"
          />
        </b-col>
      </b-row>
      <b-row v-else cols="4">
        <div
          v-for="featured in featuredTemplates"
          :key="featured.id"
          class="design-template-item"
          :style="{ backgroundImage: `url(${featured.thumb}` }"
        >
          <div class="design-overlay">
            <div class="overlay-btn">
              <b-button
                size="sm"
                variant="primary"
                class="rounded border border-secondary mr-1 px-4"
                @click="useTemplate(featured)"
              >
                Use template
              </b-button>
              <b-button
                v-if="can(user, 'bypass-subscription')"
                size="sm"
                variant="primary"
                class="rounded border border-secondary ml-1"
                @click="invokeDeleteTemplate(featured)"
              >
                <svg-icon name="trash" />
              </b-button>
            </div>
          </div>
        </div>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          v-if="$apollo.queries.designTemplates && !$apollo.queries.designTemplates.loading && !hasContents"
          class="bg-white rounded-sm shadow-sm text-center p-5"
        >
          <img class="my-3" width="300" src="@/assets/images/web_devices.svg" alt="" />
          <h5 class="pb-4">No templates yet.</h5>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="DeleteTemplateModal"
      content-class="rounded-sm text-center"
      body-class="p-5"
      centered
      hide-header
      hide-footer
      @hidden="onHideDeleteTemplateModal"
    >
      <template v-slot:default="{ hide }">
        <template v-if="!deletingTemplate">
          <h4 class="font-weight-bold mb-3">Delete Template</h4>
          <p class="m-1">This action cannot be undone</p>
          <div class="mt-4">
            <b-button variant="outline-dark" class="px-3 mr-2" size="sm" @click="hide">Cancel</b-button>
            <b-button variant="danger" class="px-3" size="sm" @click="deleteTemplate">Delete</b-button>
          </div>
        </template>
        <div v-else class="p-5"><spinner size="4" /></div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import { can } from '~/utils/helpers';

import { DESIGN_TEMPLATES_QUERY } from '~/graphql/queries';
import { DELETE_DESIGN_TEMPLATE_MUTATION } from '~/graphql/mutations';

export default {
  props: {
    editRoute: {
      required: true,
    },
    routeParam: {
      required: true,
    },
  },

  data() {
    return {
      designTemplates: {},
      templateInvokedForDelete: null,
      deletingTemplate: false,
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
      selectedContent: state => state.createPost.selectedContent,
    }),

    queryLoading() {
      return this.$apollo.queries.designTemplates && this.$apollo.queries.designTemplates.loading;
    },

    hasContents() {
      return this.designTemplates && this.designTemplates.templates && this.designTemplates.templates.length > 0;
    },

    allTemplates() {
      return this.designTemplates.templates.slice(2);
    },

    featuredTemplates() {
      return this.designTemplates.templates.slice(0, 3);
    },
  },

  apollo: {
    designTemplates: {
      query: DESIGN_TEMPLATES_QUERY,
      variables() {
        return {
          limit: 10,
        };
      },
    },
  },

  methods: {
    can,

    invokeDeleteTemplate(template) {
      this.templateInvokedForDelete = template;

      this.$bvModal.show('DeleteTemplateModal');
    },

    useTemplate(template) {
      this.$store.dispatch('design/updateTemplate', template);

      this.$router.push({ name: this.editRoute, params: { [this.routeParam]: 0 } });
    },

    deleteTemplate() {
      this.deletingTemplate = true;
      const id = this.templateInvokedForDelete.id;

      this.$apollo
        .mutate({
          mutation: DELETE_DESIGN_TEMPLATE_MUTATION,
          variables: {
            id,
          },
          update: (store, { data: { deleteDesignTemplate } }) => {
            try {
              const data = store.readQuery({
                query: DESIGN_TEMPLATES_QUERY,
                variables: {
                  limit: 10,
                },
              });
              const deleted = deleteDesignTemplate;
              const index = data.designTemplates.templates.findIndex(d => d.id === deleted.id);

              if (index !== -1) {
                data.designTemplates.templates.splice(index, 1);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: DESIGN_TEMPLATES_QUERY,
                variables: {
                  limit: 10,
                },
                data,
              });
            } catch (e) {
              // Do something with this error
              console.log(e);
            }
          },
        })
        .then(() => {
          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Template has been deleted successfully.',
          });

          this.$bvModal.hide('DeleteTemplateModal');
          this.deletingTemplate = false;
        })
        .catch(() => {
          this.deletingTemplate = false;
          this.$bvModal.hide('DeleteTemplateModal');

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideDeleteTemplateModal() {
      this.deletingTemplate = false;
      this.templateInvokedForDelete = null;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.draft__top {
  margin-bottom: 10px;
  border-radius: 10px;
  background: $secondary;
  &-text {
    padding: 30px 0 30px 40px;
    color: $primary;
    h4 {
      font-weight: bold;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }
  &-action-btn {
    background-image: url('../assets/images/new/strokes-2.svg');
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      border-radius: 10px;
    }
  }
}
.design__template {
  background: $secondary;
  padding: 20px;
  border-radius: 10px;
}

.design-templates-container {
  padding: 40px 50px;
  background-color: $light;
  border-radius: 0.7rem;
}

.contents-wrap {
  margin-top: 1.5rem;
  margin-right: -19px;
}

.featured {
  &:nth-last-of-type(2) {
    z-index: 1;
    height: 275px;
    width: 265px;
    top: -10px;
  }
  &:first-of-type {
    margin-right: -25px;
  }
  &:last-of-type {
    margin-left: -25px;
  }
}
.design-template-item {
  height: 255px;
  width: 255px;
  margin: 0 19px 24px 0;
  box-shadow: 0 7px 16px 0 rgba(22, 45, 61, 0.05);
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
  background-color: $gray-300;

  img {
    width: 100%;
    max-height: 291px;
    max-width: 291px;
  }

  .design-overlay {
    opacity: 0;
    position: absolute;
    background: rgba(22, 45, 61, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    .overlay-btn {
      margin-top: 100px;
    }
  }

  &:hover {
    transform: translateY(-6px);

    .design-overlay {
      opacity: 1;
    }
  }
}
</style>
