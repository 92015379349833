<template>
  <div class="design-element-toolbar border-top p-3">
    <div class="row p-2">
      <div v-b-tooltip.hover.top="'Delete'" @click="$emit('delete')" class="text-color-container mr-2">
        <sprite-icon icon="trash-two" />
      </div>
      <div
        v-b-tooltip.hover.top="'Duplicate'"
        @click="$emit('duplicate')"
        class="text-color-container mr-2 border-right"
      >
        <svg-icon name="duplicate" />
      </div>
      <div
        v-b-tooltip.hover.top="'Move Forward'"
        id="addTextColor"
        class="text-color-container mr-2"
        @click="$emit('moveForward')"
      >
        <sprite-icon icon="move-forward" />
      </div>
      <div
        v-b-tooltip.hover.top="'Move Backward'"
        id="addTextColor"
        class="text-color-container mr-2"
        @click="$emit('moveBackward')"
      >
        <sprite-icon icon="move-backward" />
      </div>
      <div
        v-b-tooltip.hover.top="'Bring To Front'"
        id="addTextColor"
        class="text-color-container mr-2"
        @click="$emit('sendToFront')"
      >
        <sprite-icon icon="send-to-front" />
      </div>
      <div
        v-b-tooltip.hover.top="'Send To Back'"
        id="addTextColor"
        class="text-color-container mr-2"
        @click="$emit('sendToBack')"
      >
        <sprite-icon icon="send-to-back" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DesignElementToolbar',
};
</script>

<style lang="scss">
.design-element-toolbar {
  .icon {
    color: #20455e;
    width: 0.9rem;
  }
}
</style>
