<template>
  <div class="fabs design-fabs">
    <div class="images button-wrap" :class="{ open: activePanel == 'aiImage' }">
      <button @click="openPanel('aiImage')" id="aiImage" class="link-button new fab-button">
        <svg-icon class="button-icon-new" name="aibot" size="md" /><span><span class="in">&#x1F4A1;</span>Use AI</span>
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="aiImage" target="aiImage" placement="right">
        <span class="nowhb">&#x1F4A1; Use AI</span>
      </b-tooltip>
      <div v-show="activePanel == 'aiImage'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">AI Image Generator</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>
        <div class="px-3">
          <AiImageGenerator @selectImage="handleBackgroundImageSelect" />
        </div>
      </div>
    </div>

    <div class="images button-wrap" :class="{ open: activePanel == 'templates' }">
      <button @click="openPanel('templates')" id="templateBtn" class="link-button fab-button">
        <svg-icon class="button-icon" name="template-alt" />
        Templates
      </button>
      <b-tooltip
        v-if="activePanel == null"
        triggers="hover"
        container="templateBtn"
        target="templateBtn"
        placement="right"
      >
        Templates
      </b-tooltip>
      <div v-show="activePanel == 'templates'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Templates</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <DesignFabTemplatesPanel @templateSelect="handleTemplateSelect" />
      </div>
    </div>
    <div class="uploads button-wrap" :class="{ open: activePanel == 'backgrounds' }">
      <button @click="openPanel('backgrounds')" id="backgroundBtn" class="uploads-button fab-button">
        <svg-icon class="button-icon" name="background" />
        Background
      </button>
      <b-tooltip
        v-if="activePanel == null"
        triggers="hover"
        container="backgroundBtn"
        target="backgroundBtn"
        placement="right"
        >Background</b-tooltip
      >
      <div v-show="activePanel == 'backgrounds'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Background</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <DesignFabBackgroundsPanel
          @colorSelect="handleBackgroundColorSelect"
          @imageSelect="handleBackgroundImageSelect"
        />
      </div>
    </div>
    <div class="gifs button-wrap" :class="{ open: activePanel == 'texts' }">
      <button @click="openPanel('texts')" id="textBtn" class="fab-button">
        <svg-icon class="button-icon" name="text" />
        Text
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="textBtn" target="textBtn" placement="right"
        >Text</b-tooltip
      >
      <div v-show="activePanel == 'texts'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Text</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <DesignFabTextsPanel @select="handleTextSelect" />
      </div>
    </div>
    <div class="memes button-wrap" :class="{ open: activePanel == 'graphics' }">
      <button @click="openPanel('graphics')" id="graphicsBtn" class="meme-button fab-button">
        <svg-icon class="button-icon" name="graphics" />
        Graphics
      </button>
      <b-tooltip
        v-if="activePanel == null"
        triggers="hover"
        container="graphicsBtn"
        target="graphicsBtn"
        placement="right"
        >Graphics</b-tooltip
      >
      <div v-show="activePanel == 'graphics'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Graphics</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <DesignFabGraphicsPanel @frameSelect="handleFrameSelect" />
      </div>
    </div>
    <div class="quotes button-wrap" :class="{ open: activePanel == 'uploads' }">
      <button @click="openPanel('uploads')" id="uploadBtn" class="image-button fab-button">
        <svg-icon class="button-icon" name="upload" />
        Uploads
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="uploadBtn" target="uploadBtn" placement="right"
        >Uploads</b-tooltip
      >
      <div v-show="activePanel == 'uploads'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Uploads</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <DesignFabUploadsPanel @uploadSelect="handleUploadSelect" />
      </div>
    </div>
    <div class="articles button-wrap" :class="{ open: activePanel == 'quotes' }">
      <button @click="openPanel('quotes')" id="quoteBtn" class="articles-button fab-button">
        <svg-icon class="button-icon" name="quote-alt" />
        Quotes
      </button>
      <b-tooltip v-if="activePanel == null" triggers="hover" container="quoteBtn" target="quoteBtn" placement="right"
        >Quotes</b-tooltip
      >
      <div v-show="activePanel == 'quotes'" class="panel-board" v-click-outside="hidePanel">
        <div class="panel-header d-flex justify-content-between">
          <div class="panel-header_title">Quotes</div>
          <b-button variant="clear" @click="closeAllPanels" class="panel-header_button">
            <svg-icon name="close" size="md" />
          </b-button>
        </div>

        <DesignFabQuotesPanel @select="handleQuoteSelect" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DesignFabTextsPanel from './DesignFabTextsPanel';
import DesignFabQuotesPanel from './DesignFabQuotesPanel';
import DesignFabUploadsPanel from './DesignFabUploadsPanel';
import DesignFabGraphicsPanel from './DesignFabGraphicsPanel';
import DesignFabTemplatesPanel from './DesignFabTemplatesPanel';
import DesignFabBackgroundsPanel from './DesignFabBackgroundsPanel';
import AiImageGenerator from '../Ai/AiImageGenerator';

export default {
  name: 'design-fabs',

  components: {
    DesignFabTextsPanel,
    DesignFabQuotesPanel,
    DesignFabUploadsPanel,
    DesignFabGraphicsPanel,
    DesignFabTemplatesPanel,
    DesignFabBackgroundsPanel,
    AiImageGenerator,
  },

  computed: {
    ...mapState('postEditor', {
      activePanel: state => state.openFabPanel,
    }),
  },

  methods: {
    openPanel(panel) {
      this.$store.dispatch('postEditor/updateOpenFabPanel', panel);
    },

    closeAllPanels() {
      this.$store.dispatch('postEditor/updateOpenFabPanel', null);
    },

    hidePanel(event) {
      const isClickOnFabButton = event.target.closest('.fab-button');
      const isClickOnPanel = event.target.closest('.panel-board');
      const isClickOnModal = event.target.closest('#SocialPostTutorialModal');
      const wrap = document.querySelector('.has-fabs');
      const wrapContainsTarget = wrap && wrap.contains(event.target);

      if (!isClickOnFabButton && !isClickOnPanel && !isClickOnModal && wrapContainsTarget) {
        this.closeAllPanels();
      }
    },

    handleTemplateSelect(template) {
      this.$emit('templateSelect', template);
    },

    handleBackgroundImageSelect(image) {
      console.log(image);
      this.$emit('backgroundImageSelect', image);
    },

    handleBackgroundColorSelect(color) {
      this.$emit('backgroundColorSelect', color);
    },

    handleTextSelect(text) {
      this.$emit('textSelect', text);
    },

    handleQuoteSelect(quote) {
      this.$emit('quoteSelect', quote);

      this.closeAllPanels();
    },

    handleFrameSelect(frame) {
      this.$emit('frameSelect', frame);
    },

    handleUploadSelect(upload) {
      this.$emit('uploadSelect', upload);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.fabs {
  transition: all 0.3s;
  &.design-fabs {
    background: $primary;
    left: 0;
    top: 160px;
    border-radius: 0 25px 25px 0;
    padding: 20px 0;

    .button-wrap {
      padding: 6px 20px;

      .panel-board {
        position: absolute;
        z-index: 10;
        background: $primary;
        min-height: 100px;
        box-sizing: content-box;
        width: 358px;
        left: 99px;
        top: 0;
        border-radius: 6px;
        box-shadow: 0 2px 16px 2px rgba(22, 45, 61, 0.16);
        border-radius: 25px;

        .panel-header {
          padding: 16px;
          &_title {
            padding: 6px 12px;
            text-transform: uppercase;
            background: #2d6a4f;
            color: #d8f3dc;
            border: 1px solid #b7e4c7;
            border-radius: 10px;
          }
          &_button {
            svg {
              fill: $secondary;
            }
          }
        }

        .panel-scroll {
          max-height: 445px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .panel-body {
          color: #d8f3dc;
          padding: 10px 20px;
        }
        .panel-body-top {
          padding: 8px;
          text-align: left;
          color: $white;
          border: 1px solid #b7e4c7;
          border-radius: 10px;
          .input-group {
            color: $primary !important;
            border-radius: 0.3rem !important;
            background-color: #ffffff !important;
            input {
              font-size: 0.9rem;
              height: 40px;
              width: 85%;
              &::placeholder {
                color: $gray-500;
              }
            }
          }
          .input-group-text {
            svg {
              fill: #b7e4c7;
            }
          }
          input,
          .input-group-prepend,
          .input-group-text {
            background-color: transparent;
            border: none;
            outline: none;
            &:hover,
            &:active,
            &:focus {
              background-color: transparent;
              border: none;
              outline: none;
            }
          }
        }
      }
      &:hover {
        background: #2d6a4f;
        .fab-button {
          color: $white;
          fill: $white;
        }
      }

      .fab-button {
        font-size: 12px;
        .nowhb {
          white-space: nowrap;
        }
        & .in {
          background: #2d6a4f;
          padding: 2px;
          width: 20px;
          height: 20px;
          transform: translateX(-3px);
          border-radius: 50%;
          display: inline-block;
        }
        &.new {
          color: #2d6a4f;
          font-weight: bold;
          position: relative;
          z-index: 0;
          white-space: nowrap;

          & > * {
            z-index: 1;
          }

          & .button-icon-new {
            scale: 1.8;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
          }
          &:hover::after {
            background: #aecabd;
            color: rgb(255, 255, 255) !important;

            & .in {
              background: white;
            }
          }
          &::after {
            position: absolute;
            content: ' ';
            width: calc(100% + (2 * 20px));
            right: -10px;
            bottom: -5px;
            height: 65px;

            background: #f2f2f2;
            color: #2d6a4f;
            padding: 8px;
            border-radius: 0px 20px 10px 0px;
          }
        }

        .tooltip {
          margin-left: 10px;

          .tooltip-inner {
            border-radius: $rounded-pill;
            padding: 6px 15px;

            &:empty {
              display: none;
            }
          }

          .arrow {
            display: none;
          }
        }
      }

      &.open {
        background: #2d6a4f;
        .fab-button {
          color: $white;
          .button-icon {
            fill: $white;
          }

          &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 68px;
            right: -29px;
            top: -6px;
            z-index: 13;
            background: $success;
            border-radius: 6px 0 0 6px;
          }
        }
      }
    }
  }
}
</style>
