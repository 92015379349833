const text = [
  {
    name: 'Elegant Title',
    fontFamily: 'Cantata One',
    fontSize: '28px',
    lineHeight: '1.2em',
    letterSpacing: '0em',
  },
  {
    name: 'Bold Title',
    fontFamily: 'Archivo Black',
    fontSize: '40px',
    lineHeight: '0.97em',
    letterSpacing: '0em',
  },

  {
    name: 'Classic Title',
    fontFamily: 'Abril Fatface',
    fontSize: '33px',
    lineHeight: '1.18em',
    letterSpacing: '0em',
  },
  {
    name: 'Universal Title',
    fontFamily: 'Vollkorn',
    fontSize: '28px',
    lineHeight: '1.39em',
    letterSpacing: '0.05em',
  },
  {
    name: 'minimal title',
    fontFamily: 'Lato',
    fontSize: '20px',
    lineHeight: '1.95em',
    letterSpacing: '0.35em',
  },
  {
    name: 'BUSINESS TITLE',
    fontFamily: 'Cinzel',
    fontSize: '22px',
    lineHeight: '1.2em',
    letterSpacing: '0.05em',
  },
  {
    name: 'TALL TITLE',
    fontFamily: 'Fjalla One',
    fontSize: '56px',
    lineHeight: '1.2em',
    letterSpacing: '0em',
  },
  {
    name: 'Big Title',
    fontFamily: 'Rakkas',
    fontSize: '52px',
    lineHeight: '1.2em',
    letterSpacing: '0.05em',
  },
  {
    name: 'Printer Title',
    fontFamily: 'Roboto Mono',
    fontSize: '22px',
    lineHeight: '1.77em',
    letterSpacing: '0.05em',
  },
  {
    name: "What's my title",
    fontFamily: 'Quicksand',
    fontSize: '26px',
    lineHeight: '1.5em',
    letterSpacing: '0em',
  },
];

const fonts = [
  'Cantata One',
  'Archivo Black',
  'Abril Fatface',
  'Vollkorn',
  'Lato',
  'Cinzel',
  'Fjalla One',
  'Rakkas',
  'Roboto Mono',
  'Quicksand',
];

export default text;
export { fonts };
