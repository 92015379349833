<template>
  <div class="has-fabs">
    <TopWizard :backButton="true" :goBackHandler="goBackHandler" :sequence="sequence" :currentStep="1" :sidebar="false">
      <div class="action-buttons" slot="buttons">
        <b-dropdown
          v-if="can(user, 'bypass-subscription')"
          class="design-post-dropdown custom-dropdown rounded"
          toggle-class="mr-2 m-0 act-button"
          variant="clear"
          size="sm"
          no-caret
          no-flip
          right
          offset="-15px"
          :disabled="isSaving"
        >
          <template slot="button-content">
            <b-spinner variant="primary" class="mr-1" v-if="isSaving" small />
            <template v-else>
              <svg-icon name="arrow-down" class="mr-1" />
              Save
              <svg-icon name="chevron-down" class="ml-1 mt-0" size="sm" />
            </template>
          </template>
          <div class="py-2 drop-menu rounded">
            <b-dropdown-item @click="showSaveTemplateModal">
              <svg-icon name="template" class="mr-1" />
              Save Template
            </b-dropdown-item>
            <b-dropdown-item @click="saveAndNext('save')">
              <svg-icon name="image" class="mr-1" />
              Save Design
            </b-dropdown-item>
            <b-dropdown-item @click="saveAndNext">
              <svg-icon name="plane-alt" />
              Share
            </b-dropdown-item>
          </div>
        </b-dropdown>
        <b-button
          v-else
          @click="saveAndNext('save')"
          variant="clear"
          class="border-left rounded m-0 act-button"
          :disabled="isSaving"
        >
          <b-spinner variant="primary" class="mr-1" v-if="isSaving" small />
          <svg-icon v-else name="arrow-down" class="mt-0 ml-1" />
          Save
        </b-button>
      </div>
    </TopWizard>

    <b-container class="banner-design-page-wrap">
      <div class="canvas-wrap">
        <DesignCanvas
          ref="Design"
          :saved="design.canvasData"
          :template="template"
          :width="canvasWidth"
          :height="canvasHeight"
          :loading="queryLoading"
          @modified="handleCanvasModified"
        />
      </div>
    </b-container>

    <CarouselTutorialModal type="image" :steps="tutorials" :visible="showTutorialModal">
      <template v-slot:next-button="{ close, activeStep }">
        <b-button v-if="activeStep.id === 4" @click="close" variant="success" class="px-4 mb-4 rounded-pill"
          >Let's Go</b-button
        >
      </template>
    </CarouselTutorialModal>

    <b-modal
      id="SaveTemplateModal"
      modal-class="save-template-modal"
      content-class="rounded-sm"
      size="md"
      hide-header
      hide-footer
      centered
      @hide="onHideSaveTemplateModal"
    >
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide"> <svg-icon name="close" /> </b-button>
        <div class="px-4 py-3">
          <h5 class="font-weight-bold mb-4">Publish Template</h5>
          <b-form @submit.prevent="handleTemplateFormSubmit" @keydown="templateForm.onKeydown($event)">
            <b-form-group label="Category" label-for="template-category" class="category-input-wrap">
              <b-button variant="link" size="sm" class="new-category-btn px-0" @click="showNewCategoryModal">
                Add New Category
              </b-button>
              <div v-if="$apollo.queries.designTemplatesCategories.loading" class="border rounded text-center">
                <b-spinner size="small" />
              </div>
              <SearchableSelect
                v-else
                id="workspace-timezone"
                v-model="templateForm.category"
                :options="categories"
                name="category"
              />
              <has-error :form="templateForm" field="category" />
            </b-form-group>
            <b-form-group label="Tags" label-for="tags">
              <b-form-input
                v-model="tagInput"
                id="tags"
                name="tags"
                placeholder="Press 'Enter' to add tags such as, 'Sale'"
                autocomplete="off"
                @blur="handleTagInputBlur"
                @keydown="handleTagInputKeydown"
              ></b-form-input>

              <div class="tag-items d-flex flex-wrap">
                <div
                  v-for="(tag, i) in templateForm.tags"
                  :key="i + 1"
                  class="tag-item border rounded-sm shadow-sm py-1 px-2 mt-2 mr-1"
                >
                  {{ tag }}
                  <span @click="removeTagItem(i)" class="item-remove small"> <svg-icon name="close" /> </span>
                </div>
                <has-error :form="templateForm" field="tags" />
              </div>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="shadow px-4"
              :disabled="addingTemplate || !templateForm.category || templateForm.tags.length === 0"
            >
              <b-spinner v-if="addingTemplate" class="mr-1" small></b-spinner>
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="NewCategoryModal"
      modal-class="save-template-modal"
      content-class="rounded-sm"
      size="md"
      hide-header
      hide-footer
      centered
      @show="beforeShowSaveCategoryModal"
      @hide="onHideNewCategoryModal"
    >
      <template v-slot:default="{ hide }">
        <b-button variant="link" class="close-btn" @click="hide"> <svg-icon name="close" /> </b-button>
        <div class="px-4 py-3">
          <h5 class="font-weight-bold mb-4">{{ categoryForm.id !== 0 ? 'Edit Category' : 'Add New Category' }}</h5>
          <b-form @submit.prevent="handleCategoryFormSubmit" @keydown="categoryForm.onKeydown($event)">
            <b-form-group label="Name" label-for="category-name" class="category-input-wrap">
              <b-form-input
                v-model="categoryForm.title"
                id="category-name"
                name="name"
                placeholder="Category name"
                autocomplete="off"
                trim
                required
              ></b-form-input>
              <has-error :form="categoryForm" field="name" />
            </b-form-group>
            <b-form-group label="Description" label-for="category-description">
              <b-form-textarea
                v-model="categoryForm.description"
                id="category-description"
                name="description"
                placeholder="Category description"
                trim
                required
              ></b-form-textarea>
              <has-error :form="categoryForm" field="description" />
            </b-form-group>

            <b-button type="submit" variant="primary" class="shadow px-4" :disabled="savingCategory">
              <b-spinner v-if="savingCategory" class="mr-1" small></b-spinner>
              Save
            </b-button>
          </b-form>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Form } from 'vform';
import { mapState } from 'vuex';
import { can } from '~/utils/helpers';

import TopWizard from '~/components/TopWizard';
import DesignCanvas from '~/components/DesignCanvas';
import SearchableSelect from '~/components/SearchableSelect';
import CarouselTutorialModal from '~/components/CarouselTutorialModal';

import {
  FETCH_BANNER_DESIGN_BY_ID_QUERY,
  DESIGN_TEMPLATE_CATEGORIES_QUERY,
  BANNER_DESIGNS_QUERY,
} from '~/graphql/queries';
import {
  SAVE_BANNER_DESIGN_MUTATION,
  SAVE_DESIGN_TEMPLATE_MUTATION,
  SAVE_DESIGN_TEMPLATE_CATEGORY_MUTATION,
} from '~/graphql/mutations';

export default {
  name: 'EditDesign',

  metaInfo() {
    return { title: 'Design' };
  },

  components: {
    TopWizard,
    DesignCanvas,
    SearchableSelect,
    CarouselTutorialModal,
  },

  data() {
    return {
      canvasWidth: '500',
      canvasHeight: '500',
      design: {},

      isSaving: false,

      tutorials: [
        {
          id: 1,
          title: 'Welcome to Image Editor',
          description: 'Stunning Graphics for your Brand in just a few Clicks.',
          image: 'step-1.gif',
        },
        {
          id: 2,
          title: 'Preloaded Assets ',
          description:
            'Find a great selection of Images, Backgrounds, Patterns, Shapes and more Square, Story Vertical, Landscape or even custom',
          image: 'step-2.gif',
        },
        {
          id: 3,
          title: 'Full Control',
          description: 'Select from premium fonts, Colors, layer rotation and other tools to create just the perfect ',
          image: 'step-3.gif',
        },
        {
          id: 4,
          title: 'Share or Save',
          description: 'Share your Content in 1 click to your Social media or simply save for use anywhere you want',
          image: 'step-4.gif',
        },
      ],

      tagInput: '',
      templateForm: new Form({
        id: 0,
        category: null,
        tags: [],
      }),
      addingTemplate: false,

      categoryForm: new Form({
        id: 0,
        title: '',
        description: '',
      }),
      savingCategory: false,

      queryLoading: false,
    };
  },

  apollo: {
    designTemplatesCategories: {
      query: DESIGN_TEMPLATE_CATEGORIES_QUERY,
      variables() {
        return {
          limit: 50,
        };
      },
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      workspace: state => state.workspace.current,
      canvasModified: state => state.design.modified,
      showTutorialModal: state => state.auth.user.options.onboarding.imageDesigner,
      template: state => state.design.template,
      invokedCategory: state => state.design.invokedCategory,
    }),

    isDesignSaved() {
      return String(this.$route.params.id || '0') !== '0';
    },

    sequence() {
      return [
        {
          number: 1,
          name: 'Design',
          to: { name: 'designer' },
        },
        {
          number: 2,
          name: 'Preview & Share',
          to: { name: 'designer.share' },
          disabled: !this.isDesignSaved,
        },
      ];
    },

    categories() {
      const cats = this.designTemplatesCategories.categories || [];
      const categories = cats.map(c => ({
        value: c.id,
        text: c.title,
      }));
      return [{ value: null, text: 'Select Category' }, ...categories];
    },
  },

  created() {
    if (this.template) {
      return;
    }

    if (this.$route.params.id == 0) {
      return (this.design = { canvasData: null });
    }

    this.queryLoading = true;
    this.$apollo
      .query({
        query: FETCH_BANNER_DESIGN_BY_ID_QUERY,
        variables: {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10),
        },
      })
      .then(({ data }) => {
        this.queryLoading = false;

        if (data.bannerDesignById) {
          this.design = data.bannerDesignById;
        }
      })
      .catch(() => {
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Error processing your request.',
        });

        this.$router.push({ name: 'designer.designs' }, () => {
          this.queryLoading = false;
        });
      });
  },

  mounted() {
    this.$store.dispatch('design/updateIsModified', false);
    this.$store.dispatch('postEditor/updateOpenFabPanel', 'templates');
  },

  methods: {
    can,

    goBackHandler() {
      this.$router.push({ name: 'designer.overview' });
    },

    handleCanvasModified(isModified) {
      this.$store.dispatch('design/updateIsModified', isModified);
    },

    showSaveTemplateModal() {
      if (!this.canvasModified) {
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Please create a design to continue...',
        });
        return;
      }

      this.$bvModal.show('SaveTemplateModal');
    },

    handleTagInputBlur() {
      if (this.tagInput) {
        const tag = this.tagInput;
        this.tagInput = '';

        if (!this.templateForm.tags.find(h => h === tag)) {
          this.templateForm.tags = [...this.templateForm.tags, tag];
        }
      }
    },

    handleTagInputKeydown(e) {
      if (e.code == 'Comma' || e.code == 'Enter') {
        e.preventDefault();

        const tag = this.tagInput;
        this.tagInput = '';

        if (!this.templateForm.tags.find(h => h === tag)) {
          this.templateForm.tags = [...this.templateForm.tags, tag];
        }
      }
    },

    removeTagItem(index) {
      this.templateForm.tags = [...this.templateForm.tags.slice(0, index), ...this.templateForm.tags.slice(index + 1)];
    },

    handleTemplateFormSubmit() {
      if (!this.canvasModified) {
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Please create a design to continue...',
        });
        return;
      }

      this.addingTemplate = true;
      const canvasJSON = JSON.stringify(this.$refs.Design.canvas.toJSON());

      const dataURL = this.$refs.Design.canvas.toDataURL({
        format: 'png',
      });

      this.$apollo
        .mutate({
          mutation: SAVE_DESIGN_TEMPLATE_MUTATION,
          variables: {
            id: parseInt(this.templateForm.id, 10) || 0,
            category: this.templateForm.category,
            tags: this.templateForm.tags,
            canvasData: canvasJSON,
            dataURL,
          },
          update: (store, { data: { saveDesignTemplate: saved } }) => {
            try {
              const data = store.readQuery({
                query: DESIGN_TEMPLATE_CATEGORIES_QUERY,
                variables: { limit: 50 },
              });

              const category = data.designTemplatesCategories.categories.find(c => c.id === this.templateForm.category);
              const index = category.templates.findIndex(t => t.id === saved.id);
              if (index !== -1) {
                category.templates.splice(index, 1, saved);
              } else {
                category.templates.unshift(saved);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: DESIGN_TEMPLATE_CATEGORIES_QUERY,
                variables: { limit: 50 },
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(() => {
          this.$bvModal.hide('SaveTemplateModal');

          this.addingTemplate = false;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Template saved successfully',
          });
        })
        .catch(error => {
          this.addingTemplate = false;

          const validations = error.graphQLErrors.filter(err => err.message == 'validation');

          if (validations.length) {
            validations.forEach(err => {
              this.templateForm.errors.set(err.extensions.validation);
            });
          }

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    showNewCategoryModal() {
      this.$bvModal.show('NewCategoryModal');
    },

    handleCategoryFormSubmit() {
      this.savingCategory = true;

      this.$apollo
        .mutate({
          mutation: SAVE_DESIGN_TEMPLATE_CATEGORY_MUTATION,
          variables: {
            id: this.categoryForm.id,
            title: this.categoryForm.title,
            description: this.categoryForm.description,
          },
          update: (store, { data: { saveDesignTemplateCategory: saved } }) => {
            try {
              const data = store.readQuery({
                query: DESIGN_TEMPLATE_CATEGORIES_QUERY,
                variables: { limit: 50 },
              });

              const index = data.designTemplatesCategories.categories.findIndex(u => u.id === saved.id);
              if (index !== -1) {
                data.designTemplatesCategories.categories.splice(index, 1, saved);
              } else {
                data.designTemplatesCategories.categories.push(saved);
              }

              // Write our data back to the cache.
              store.writeQuery({
                query: DESIGN_TEMPLATE_CATEGORIES_QUERY,
                variables: { limit: 50 },
                data,
              });
            } catch (e) {
              // Do something with this error
            }
          },
        })
        .then(({ data: { saveDesignTemplateCategory } }) => {
          this.$bvModal.hide('NewCategoryModal');

          this.savingCategory = false;

          this.templateForm.category = saveDesignTemplateCategory.id;

          this.$notify({
            group: 'main',
            type: 'native',
            text: 'Category saved successfully',
          });
        })
        .catch(error => {
          this.savingCategory = false;

          const validations = error.graphQLErrors.filter(err => err.message == 'validation');

          if (validations.length) {
            validations.forEach(err => {
              this.categoryForm.errors.set(err.extensions.validation);
            });
          }

          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'An error occurred while processing your request.',
          });
        });
    },

    onHideNewCategoryModal() {
      this.categoryForm.reset();
    },

    beforeShowSaveCategoryModal() {
      if (this.invokedCategory) {
        this.categoryForm.keys().forEach(key => {
          this.categoryForm[key] = this.invokedCategory[key];
        });
      }
    },

    onHideSaveTemplateModal() {
      this.templateForm.reset();
    },

    saveAndNext(type) {
      if (this.queryLoading) {
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Design is currently compilin, Please try again later...',
        });

        return;
      }

      if (!this.canvasModified) {
        if (!this.isDesignSaved) {
          this.$notify({
            group: 'main',
            type: 'native-error',
            text: 'Please create a design to continue...',
          });
          return;
        }

        return this.handleSaved(this.$route.params.id, type);
      }

      try {
        this.isSaving = true;
        const dataURL = this.$refs.Design.canvas.toDataURL({
          format: 'png',
        });

        const dataJson = JSON.stringify(this.$refs.Design.canvas.toJSON());

        this.$apollo
          .mutate({
            mutation: SAVE_BANNER_DESIGN_MUTATION,
            variables: {
              workspace: this.workspace.id,
              id: parseInt(this.$route.params.id, 10) || 0,
              canvasData: dataJson,
              dataURL,
            },
            update: (store, { data: { saved } }) => {
              try {
                store.writeQuery({
                  query: FETCH_BANNER_DESIGN_BY_ID_QUERY,
                  variables: {
                    workspace: this.workspace.id,
                    id: parseInt(this.$route.params.id, 10),
                  },
                  data: { bannerDesignById: saved },
                });

                const myDesignsData = store.readQuery({
                  query: BANNER_DESIGNS_QUERY,
                  variables: {
                    workspace: this.workspace.id,
                    limit: 10,
                  },
                });

                const index = myDesignsData.bannerDesigns.designs.findIndex(d => d.id === saved.id);
                if (index !== -1) {
                  myDesignsData.bannerDesigns.designs.splice(index, 1, saved);
                } else {
                  myDesignsData.bannerDesigns.designs.unshift(saved);
                }

                // Write our data back to the cache.
                store.writeQuery({
                  query: BANNER_DESIGNS_QUERY,
                  variables: {
                    workspace: this.workspace.id,
                    limit: 10,
                  },
                  myDesignsData,
                });
              } catch (e) {
                // Do something with this error
              }
            },
          })
          .then(({ data }) => {
            this.isSaving = false;
            this.$store.dispatch('design/updateIsModified', false);

            if (data.saved) {
              this.handleSaved(data.saved.id, type);
            }
          })
          .catch(() => {
            this.isSaving = false;

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'Design could not be saved. Please try again.',
            });
          });
      } catch (e) {
        this.isSaving = false;
        this.$notify({
          group: 'main',
          type: 'native-error',
          text: 'Design could not be saved. An error occurred while processing image design',
        });
      }
    },

    async handleSaved(designId, type) {
      if (String(designId || '0') !== '0') {
        if (type === 'save') {
          this.$router.push({ name: 'designer.edit', params: { id: designId } }).catch(() => {});
          this.$notify({
            group: 'main',
            title: 'Success!',
            text: 'Design has been saved successfully',
          });
        } else {
          this.$router.push({ name: 'designer.share', params: { id: designId } }).catch(() => {});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.banner-design-page-wrap {
  padding-top: $topbar-height;
  margin-top: 2.2rem;
}
.canvas-wrap {
  display: flex;
  justify-content: center;
  align-content: center;
}

.save-template-modal {
  .close-btn {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .category-input-wrap {
    position: relative;

    .new-category-btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  label {
    margin-bottom: 0;
  }

  .custom-checkbox {
    label {
      line-height: 1.7;
    }
  }

  .form-control {
    font-size: 0.9rem;

    &::placeholder {
      color: $gray-500;
    }
  }

  .count-input {
    input {
      font-size: 0.9rem;
      padding: 10px;
      color: #3a4557;
      border-radius: 5px;
      border: 1px solid rgba(152, 158, 181, 0.5);
      background: transparent;
      margin-right: 5px;
      margin-left: 5px;

      &:focus {
        border-color: $primary;
        outline: 0;
      }
    }
    label {
      font-size: 15px;
    }
  }

  .tag-items {
    .tag-item {
      font-size: 0.8rem;

      .item-remove {
        cursor: pointer;
        font-weight: bold;
        margin-left: 0.3rem;

        .icon {
          width: 0.5rem;
          height: 0.5rem;
          margin-top: 0;
          fill: $gray-800;
        }
      }
    }
  }
}
</style>
